<template>
  <v-container fluid v-if="customer" class="text-center">
    <h1>Links for {{ customer.name }}</h1>
    <div class="ma-5 text-center">
      <span class="mx-5" v-for="(link, index) in getCustomerLinks" :key="index">
        <v-btn color="#0097A7" class="white--text" :href="link.link" target="_blank" rounded>
          {{ link.name }}
        </v-btn>
      </span>
    </div>
  </v-container>
</template>
<script>
export default {
  props: ["mandator"],
  computed: {
    customer() {
      return this.$store.getters.customer(this.mandator);
    },
    getCustomerLinks() {
      const links = [];
      if (this.customer.contentfulSpaceId) {
        links.push({
          link: `https://app.contentful.com/spaces/${this.customer.contentfulSpaceId}`,
          name: "Contentful Space",
        });
      }

      if (this.customer.brazeAppGroupId) {
        links.push({
          link: `https://dashboard-01.braze.eu/dashboard/app_usage/${this.customer.brazeAppGroupId}`,
          name: "Braze App Group",
        });
      }
      if (this.customer.kubernetesNamespace) {
        links.push({
          link: `https://${this.customer.kubernetesNamespace}.production.anexia.skiline.cc`,
          name: `Node-RED Flows`,
        });
      }
      return links;
    },
  },
};
</script>
<style scoped>
.main {
  height: 98%;
  width: 98%;
  padding: 20px !important;
  margin: 20px !important;
}
</style>
