<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Visualize Content Model</h2>
      <v-btn style="float:right" @click="getSpaces(true)"
        >Refresh Contentful Spaces
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        ...
      </v-alert>
      <!-- select Spaces -->
      <strong>Source Space</strong>
      <v-row class="ma-2">
        <v-select
          style="width:50%"
          outlined
          :items="spaces"
          item-text="name"
          item-value="id"
          v-model="selection.sourceSpace"
        />
        <v-select
          style="width:50%"
          :disabled="selection.sourceSpace == null"
          outlined
          :items="sourceEnvs"
          item-text="name"
          value="id"
          v-model="selection.sourceEnv"
        />
      </v-row>
      <v-btn class="primary-button" dark @click="visualize()" :loading="loading.operation">Visualize Model</v-btn>
    </v-card>
    <br />
    <div></div>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";
import * as d3 from "d3";

export default {
  props: ["isLoading"],
  data() {
    return {
      messages: [],
      loading: {
        spaces: false,
        operation: false,
      },
      selection: {
        sourceSpace: null,
        sourceEnv: null,
      },
      spaces: [],
      contentModel: null,
    };
  },
  components: {
    SnackBarQueue,
  },
  watch: {},
  computed: {
    sourceEnvs() {
      if (this.selection.sourceSpace) {
        let space = this.spaces.find((space) => this.selection.sourceSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },
  methods: {
    visualize() {
      var width = 960,
        height = 500;
      var svg = d3
        .select("body")
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      var force = d3.layout
        .force()
        .gravity(0.05)
        .distance(100)
        .charge(-100)
        .size([width, height]);

      const json = {
        nodes: [
          { name: "node1", group: 1, id:1 },
          { name: "node2", group: 2, id:2 },
          { name: "node3", group: 3, id:3 },
          { name: "node4", group: 4, id:4 },
        ],
        links: [
          { source: 2, target: 3, weight: 3 },
        ],
      };

      force
        .nodes(json.nodes)
        .links(json.links)
        .start();

      var link = svg
        .selectAll(".link")
        .data(json.links)
        .enter()
        .append("line")
        .attr("class", "link")
        .style("stroke-width", function(d) {
          return Math.sqrt(d.weight);
        });

      var node = svg
        .selectAll(".node")
        .data(json.nodes)
        .enter()
        .append("g")
        .attr("class", "node")
        .call(force.drag);

      node.append("circle").attr("r", "5");

      node
        .append("text")
        .attr("dx", 12)
        .attr("dy", ".35em")
        .text(function(d) {
          return d.name;
        });

      force.on("tick", function() {
        link
          .attr("x1", function(d) {
            return d.source.x;
          })
          .attr("y1", function(d) {
            return d.source.y;
          })
          .attr("x2", function(d) {
            return d.target.x;
          })
          .attr("y2", function(d) {
            return d.target.y;
          });

        node.attr("transform", function(d) {
          return "translate(" + d.x + "," + d.y + ")";
        });
      });
    },
    async loadModel() {
      if (!(this.selection.sourceSpace && this.selection.sourceEnv)) {
        return this.messages.push("Please select a space and env first!");
      }
      this.loading.operation = true;
      this.axios
        .get(`/contentful/visualiseModel?spaceId=${this.selection.sourceSpace}&environmentId=${this.selection.sourceEnv}`)
        .then((res) => {
          this.nodes = res.data.contentModel.nodes;
          this.edges = res.data.contentModel.edges;
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => (this.loading.operation = false));
    },
    async getSpaces(refresh = false) {
      let cfSpaces = this.$store.state.contentfulSpaces;

      if (cfSpaces?.length > 0 && !refresh) {
        this.spaces = cfSpaces;
      } else {
        this.loading.spaces = true;
        this.$emit("loadingStatusChanged", true)
        this.axios
          .get(`/contentful/spaces`)
          .then((res) => {
            this.spaces = res.data.spaces ?? [];
            this.$store.commit("setContentfulSpaces", res.data.spaces);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          })
          .catch((err) => {
            this.messages.push({
              message: `Failed loading spaces! ${err.response ? err.response.data.message : err.message}`,
              color: "error",
            });
            console.error(err);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          });
      }
    },
  },

  mounted() {
    this.getSpaces();
  },
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}
.content_field_box {
  background-color: #7ecead61;
}
.inset {
  margin-left: 40px;
}
</style>
<style>

.link {
  stroke: #aaa;
}

.node text {
stroke:#333;
cursor:pointer;
}

.node circle{
stroke:#fff;
stroke-width:3px;
fill:#555;
}

</style>
