<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Copy User Roles</h2>
      <v-btn style="float:right" @click="getSpaces(true)">
        Refresh Contentful Spaces
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-divider class="horizontal-divider" />
      <br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>This function copies User Roles from the source space to the target space.
      </v-alert>
      <!-- select Spaces -->
      <v-row class="ma-2">
        <v-col>
          <strong>Source Space</strong>
          <v-select outlined :items="spaces" item-text="name" item-value="id" v-model="selection.sourceSpace" />
        </v-col>
        <v-col>
          <strong>Source User Role</strong>
          <v-select
            :disabled="selection.sourceSpace == null"
            outlined
            :items="sourceUserRoles"
            item-text="name"
            return-object
            v-model="selection.sourceUserRole"
          />
        </v-col>
      </v-row>
      <v-row class="ma-3">
        <v-col>
          <strong>Target Space</strong>
          <v-select outlined :items="spaces" item-text="name" item-value="id" v-model="selection.targetSpace" />
        </v-col>
        <v-col>
          <strong>Target User Role</strong>
          <v-select
            :disabled="selection.targetSpace == null"
            outlined
            :items="targetUserRoles"
            item-text="name"
            return-object
            v-model="selection.targetUserRole"
          />
        </v-col>
        <v-text-field
          v-if="nameCheck"
          v-model="selection.suggestedName"
          :error="suggestedNameError"
          :rules="[rules.nameExists]"
          placeholder="Enter a name"
        />
        <v-text-field
          v-else-if="nameCheck2"
          v-model="selection.suggestedName"
          :error="suggestedNameError"
          :rules="[rules.nameExists]"
          placeholder="Enter a name"
        />
      </v-row>
      <v-data-table
        v-if="selection.tags && selection.tags.length > 0"
        :headers="headers"
        :items="selection.tags"
        item-key="sourceTag"
        class="mb-3"
      >
        <template v-slot:item.operation="{ item }">
          <v-select
            v-model="item.operation"
            :items="tagOptions"
            :error="checkDropdown(item)"
            :rules="[rules.tagExists(item)]"
          ></v-select>
        </template>
        <template v-slot:item.targetTags="{ item }">
          <v-select v-model="item.targetTags" :items="targetTags" multiple></v-select>
        </template>
      </v-data-table>
      <a
        v-if="response"
        target="_blank"
        :href="`https://app.contentful.com/spaces/${selection.targetSpace}/settings/roles/${response}/details`"
        ><b>Open User Role in Contentful</b></a
      >
      <v-btn class="primary-button" dark :disabled="suggestedNameError" @click="copyUserRole()" :loading="loading.migration"
        >Copy User Role</v-btn
      >
    </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";

export default {
  props: ["isLoading"],
  name: "ContentUserRolesMigrate",
  data() {
    return {
      messages: [],
      loading: {
        spaces: false,
        migration: false,
      },
      selection: {
        sourceSpace: null,
        sourceUserRole: null,
        targetSpace: null,
        targetUserRole: null,
        suggestedName: null,
        tags: [],
      },
      tagOptions: ["keep", "map to", "remove"],
      contentTypes: "",
      spaces: [],
      result: null,
      pollJob: null,
      jobProgress: 0,
      response: null,
      sourceUserRoles: [],
      targetUserRoles: [],
      targetTags: [],
      suggestedNameError: false,
      rules: {
        nameExists: () => !this.suggestedNameError || `The entered Name already exists.`,
        tagExists: (item) => !this.checkDropdown(item) || "Tag does not exist in target space",
      },
      headers: [
        { text: "Source tags", value: "sourceTag" },
        { text: "Operation", value: "operation" },
        { text: "Target tags", value: "targetTags" },
      ],
    };
  },
  components: {
    SnackBarQueue,
  },
  watch: {
    "selection.sourceSpace"() {
      this.response = null;
      this.selection.sourceUserRole = null;
      if (this.selection.sourceSpace) {
        this.getRoles(this.selection.sourceSpace, "source");
      }
    },
    "selection.targetSpace"() {
      this.response = null;
      this.selection.targetUserRole = null;
      if (this.selection.targetSpace) {
        this.getRoles(this.selection.targetSpace, "target");
        this.getTags(this.selection.targetSpace);
      }
    },
    "selection.suggestedName"(val) {
      if (val) {
        if (this.selection.targetSpace) {
          this.suggestedNameError = this.targetUserRoles.some((x) => x.name == val.trim());
        } else {
          this.suggestedNameError = false;
        }
      } else {
        this.suggestedNameError = false;
      }
    },
    nameCheck(val) {
      if (val) {
        if (this.selection.targetSpace) {
          let count = this.targetUserRoles.filter((x) => x.name.includes(this.selection.sourceUserRole.name)).length;
          this.selection.suggestedName = this.selection.sourceUserRole.name + " " + (count + 1);
        } else {
          this.selection.suggestedName = null;
        }
      } else {
        this.selection.suggestedName = null;
      }
    },
    nameCheck2(val) {
      if (val) {
        if (this.selection.targetSpace) {
          this.selection.suggestedName = this.selection.sourceUserRole.name;
        } else {
          this.selection.suggestedName = null;
        }
      } else {
        this.selection.suggestedName = null;
      }
    },
    sourceTags(val) {
      this.selection.tags = val.map((x) => ({
        sourceTag: x,
        operation: "keep",
        targetTags: [],
      }));
    },
  },
  computed: {
    nameCheck() {
      if (
        this.selection.sourceUserRole &&
        this.selection.targetUserRole &&
        this.selection.targetUserRole.name == "Create new User Role"
      ) {
        return this.targetUserRoles.some((x) => x.name == this.selection.sourceUserRole.name);
      }
      return false;
    },
    nameCheck2() {
      if (
        this.selection.sourceUserRole &&
        this.selection.targetUserRole &&
        this.selection.targetUserRole.name == "Create new User Role"
      ) {
        return true;
      }
      return false;
    },
    tagCheck() {
      if (!this.selection.tags) {
        return false;
      }
      if (this.selection.tags.length == 0) {
        return false;
      }

      let keepTags = this.selection.tags.filter((x) => x.operation == "keep");
      return keepTags.some((x) => !this.targetTags.includes(x.sourceTag));
    },
    sourceTags() {
      if (this.selection.targetUserRole && this.selection.sourceUserRole && this.selection.sourceUserRole.policies) {
        let allTags = [];
        const allIns = this.selection.sourceUserRole.policies
          .filter((x) => x.constraint.and != null)
          .map((x) => x.constraint.and.filter((t) => t.in != null));
        const srcTags = allIns.filter((x) => x.length > 0).map((x) => x.map((t) => t.in));
        srcTags.forEach((e) => {
          let doc = e[0][0];
          if (doc.doc == "metadata.tags.sys.id") {
            let tags = e[0][1];
            allTags = allTags.concat(tags);
          }
        });
        return [...new Set(allTags)];
      }
      return [];
    },
  },
  methods: {
    checkDropdown(item) {
      return item && item.operation == "keep" && !this.targetTags.some((x) => x == item.sourceTag);
    },
    async getSpaces(refresh = false) {
      let cfSpaces = this.$store.state.contentfulSpaces;

      if (cfSpaces?.length > 0 && !refresh) {
        this.spaces = cfSpaces;
      } else {
        this.selection.sourceUserRole = null;
        this.selection.targetUserRole = null;
        this.selection.sourceSpace = null;
        this.selection.targetSpace = null;
        this.loading.spaces = true;
        this.$emit("loadingStatusChanged", true)
        this.axios
          .get(`/contentful/spaces`)
          .then((res) => {
            this.spaces = res.data.spaces ?? [];
            this.$store.commit("setContentfulSpaces", res.data.spaces);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          })
          .catch((err) => {
            this.messages.push({
              message: `Failed loading spaces! ${err.response ? err.response.data.message : err.message}`,
              color: "error",
            });
            console.error(err);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          });
      }
    },
    async getRoles(spaceId, type) {
      this.loading.userRoles = true;
      this.axios
        .get(`/contentful/userRoles/${spaceId}`)
        .then((res) => {
          if (type == "source") {
            this.selection.sourceUserRole = null;
            this.sourceUserRoles = res.data;
          } else {
            this.selection.targetUserRole = null;
            this.targetUserRoles = res.data;
            this.targetUserRoles.unshift({ name: "Create new User Role" });
          }
          this.loading.userRoles = false;
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading userRoles! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
          this.loading.userRoles = false;
        });
    },
    async getTags(spaceId) {
      this.loading.tags = true;
      this.axios
        .get(`/contentful/tags/${spaceId}`)
        .then((res) => {
          this.targetTags = res.data;
          this.loading.tags = false;
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading tags! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
          this.loading.tags = false;
        });
    },
    copyUserRole() {
      if (
        !(
          this.selection.sourceSpace &&
          this.selection.targetSpace &&
          this.selection.sourceUserRole &&
          this.selection.targetUserRole
        )
      ) {
        return this.messages.push("Selection incomplete!");
      }

      this.loading.migration = true;
      this.result = null;
      this.axios
        .post("/contentful/copy-user-roles", this.selection)
        .then((res) => {
          this.response = res.data.response.sys.id;
          this.loading.migration = false;
          this.messages.push({
            message: `Successfuly copied User Role!`,
            color: "success",
            timeout: 4000,
          });
        })
        .catch((err) => {
          this.loading.migration = false;
          this.messages.push({
            message: `Failed copying User Role! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        });
    },
  },

  created() {
    this.getSpaces();
  },
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}
.content_field_box {
  background-color: #7ecead61;
}
.inset {
  margin-left: 40px;
}
</style>
