<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Compare Content Models</h2>
      <v-btn style="float:right" @click="getSpaces(true)">Refresh Contentful Spaces
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        Compares two Contentful content models and highlights differences for the entry fields.
      </v-alert>
      <!-- select Spaces -->
      <strong>Source Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id"
          v-model="selection.sourceSpace" />
        <v-select style="width:50%" :disabled="selection.sourceSpace == null" outlined :items="sourceEnvs"
          item-text="name" value="id" v-model="selection.sourceEnv" />
      </v-row>
      <strong>Target Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id"
          v-model="selection.targetSpace" />
        <v-select style="width:50%" :disabled="selection.targetSpace == null" outlined :items="targetEnvs"
          item-text="name" value="id" v-model="selection.targetEnv" />
      </v-row>
      <v-btn class="primary-button" dark @click="loadModels()" :loading="loading.models">Compare Content Models</v-btn>
    </v-card>
    <br />

    <!-- Content Model Diff Operator Result-->
    <v-card outlined flat v-if="true">
      <div style="padding:20px">
        <v-list v-for="(cm, cm_index) in result" :key="cm_index">
          <template v-if="cm.match_found">
            <template v-if="cm.perfect_match">
              <v-list-item class="green lighten-4">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon color="#008000">mdi-check-circle-outline</v-icon>
                    <b>Content Type:</b> {{ cm.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-group style="padding-left:-16px;margin-left:-16px">
                <v-list-item class="red lighten-4" slot="activator">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="#DD2C00">mdi-close-circle-outline</v-icon>
                      <b>Content Type:</b> {{ cm.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Content Type found in Target, but with differences.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list v-for="(field_result, field_result_index) in cm.field_results" :key="field_result_index"
                  style="padding-left:20px">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon v-if="field_result.perfect_field_match" color="#008000">mdi-check-circle-outline
                        </v-icon>
                        <v-icon v-if="!field_result.perfect_field_match" color="#DD2C00">mdi-close-circle-outline
                        </v-icon>
                        {{ field_result.field_name }}
                      </v-list-item-title>
                      <div v-if="!field_result.perfect_field_match">
                        <v-list-item-subtitle>
                          {{ field_result | displayResult }}
                        </v-list-item-subtitle>
                        <ul class="mt-2" v-for="(difference, difference_index) in field_result.differences"
                          :key="difference_index">
                          <li>{{ difference.key }}
                            <ul>
                              <li><strong>Source:</strong> {{ difference.source_value | displayValue }}</li>
                              <li><strong>Target:</strong> {{ difference.target_value | displayValue }}</li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-group>
            </template>
          </template>
          <template v-else>
            <v-list-item class="red lighten-4">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon color="#FF0000">mdi-close-circle-outline</v-icon>
                  <b>Content Type:</b> {{ cm.name }}
                </v-list-item-title>
                <v-list-item-subtitle>Content Type not found in Target.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card>
  </v-container>
</template>

<script>
const queryString = require("query-string");
const deepDiff = require("deep-diff").diff;
import SnackBarQueue from "../helpers/SnackBarQueue";

export default {
  data() {
    return {
      messages: [],
      loading: {
        spaces: false,
      },
      selection: {
        sourceSpace: null,
        sourceEnv: null,
        targetSpace: null,
        targetEnv: null,
      },
      finished_diff: false,
      count: 0,
      count_differ: 0,
      show_results: true,
      result: [],
      spaces: [],
    };
  },
  components: {
    SnackBarQueue,
  },
  watch: {},
  filters: {
    displayResult: function (val) {
      var expr = val.type;
      switch (expr) {
        case "same_order":
          if (val.differences.length > 0) {
            return "There is a difference in the fields.";
          } else {
            return "There is no difference in the fields.";
          }
        case "not_found_in_target":
          return "The field is missing in the target.";
        case "wrong_order":
          return "The order of the fields is different.";
      }
    },
    displayValue: function (val) {
      return typeof val === "object" ?
        JSON.stringify(val, null, 2) : val
    },

  },
  computed: {
    sourceEnvs() {
      if (this.selection.sourceSpace) {
        let space = this.spaces.find((space) => this.selection.sourceSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
    targetEnvs() {
      if (this.selection.targetSpace) {
        let space = this.spaces.find((space) => this.selection.targetSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },
  methods: {
    async loadModels() {
      try {
        if (
          !(this.selection.sourceSpace && this.selection.targetSpace && this.selection.sourceEnv && this.selection.targetEnv)
        ) {
          return this.messages.push("Please select Source and Target Space first!");
        }

        if (
          this.selection.sourceSpace === this.selection.targetSpace &&
          this.selection.sourceEnv === this.selection.targetEnv
        ) {
          return this.messages.push({
            message: "Source and target are the same. Please change your selection!",
            color: "error",
          });
        }

        this.reloadComponent();
        this.loading.models = true;
        let response = await this.axios.get(
          "/contentful/contentmodel?" +
          queryString.stringify({
            spaceId: this.selection.sourceSpace,
            environmentId: this.selection.sourceEnv,
          })
        );
        const sourceModel = response.data.contentModel;
        response = await this.axios.get(
          "/contentful/contentmodel?" +
          queryString.stringify({
            spaceId: this.selection.targetSpace,
            environmentId: this.selection.targetEnv,
          })
        );
        const targetModel = response.data.contentModel;
        this.contentModelDiff(sourceModel.items.sort((a, b) => (a.name > b.name) - (a.name < b.name)), targetModel.items);
        this.loading.models = false;
      } catch (err) {
        this.loading.models = false;
        console.error(err);
        this.messages.push({ message: err.message, color: "error" });
      }
    },
    /**
     * Reloading components
     */
    reloadComponent() {
      this.show_results = false;
      this.count = 0;
      this.count_differ = 0;
      this.result = [];
    },
    /**
     * Deep Diff for validations...
     */
    deepDiffOperation(lhs, rhs) {
      return deepDiff(lhs, rhs);
    },
    /**
     * Content Model Diff Operator
     */
    contentModelDiff(contentmodel1, contentmodel2) {
      try {
        this.finished_diff = false;

        //links the counterparts of the selected contentmodels
        contentmodel1.forEach((model) => {
          var match = contentmodel2.find((target) => target.sys.id == model.sys.id);
          var cm_result = {
            field_results: [],
            contentmodel: model.sys.id,
            name: model.name,
            match_found: !!match,
            perfect_match: true,
          };
          if (match) {
            //map the fields of the linked cm
            //iterate over fields
            model.fields.forEach((field, index_source) => {
              var index_target = match.fields.findIndex((field_match) => field.id == field_match.id);
              //to store differences between cm
              var field_res = {
                field_id: field.id,
                field_name: field.name,
                type: null,
                perfect_field_match: true,
                differences: [],
              };
              //index are compared to check order in contentmodel (example with braze config in contentful)
              //index_target = -1 indicating cm does not contain field when comparing cm1 and cm2
              if (index_target == -1) {
                field_res.type = "not_found_in_target"; //an error occured
                field_res.perfect_field_match = false;
              } else {
                //for the differences
                if (index_source == index_target) {
                  //order same --> no "error"
                  field_res.type = "same_order";
                } else {
                  //wrong order --> spaces differ
                  field_res.type = "wrong_order";
                  field_res.perfect_field_match = false;
                }
                //found differences between cm
                var fieldmatch = match.fields[index_target];
                for (var key in field) {
                  if (
                    field.hasOwnProperty(key) &&
                    key != "defaultValue" &&
                    key != "validations" &&
                    key != "items" &&
                    key != "control"
                  ) {
                    if (field[key] != fieldmatch[key]) {
                      console.log("Property diff for model " + model.sys.id + " in field " + field.id + " for property " + key, field[key], fieldmatch[key])
                      field_res.perfect_field_match = false;
                      field_res.differences.push({
                        key,
                        source_value: field[key],
                        target_value: fieldmatch[key],
                      });
                    }
                  }
                }

                const sortValidations = (val) => {
                  if (typeof val === "object") {
                    for (const key in val) {
                      if (Array.isArray(val[key])) val[key] = val[key].sort();
                    }
                  }
                }
                // order does not matter for validations
                field.validations.forEach(sortValidations)
                fieldmatch.validations.forEach(sortValidations)
                field.items?.validations.forEach(sortValidations)
                fieldmatch.items?.validations.forEach(sortValidations)

                var validation_diff = this.deepDiffOperation(field.validations, fieldmatch.validations);
                if (validation_diff) {
                  console.log("Validation diff for model " + model.sys.id + " in field " + field.id, field.validations, fieldmatch.validations)
                  field_res.differences.push({
                    key: "validations",
                    source_value: field.validations,
                    target_value: fieldmatch.validations,
                  });
                }

                var items_diff = this.deepDiffOperation(field.items, fieldmatch.items);
                if (items_diff) {
                  console.log("Items diff for model " + model.sys.id + " in field " + field.id, field.items, fieldmatch.items)
                  field_res.differences.push({
                    key: "items",
                    source_value: field.items,
                    target_value: fieldmatch.items,
                  });
                }

                var control_diff = this.deepDiffOperation(field.control, fieldmatch.control);
                if (control_diff) {
                  console.log("Control diff for model " + model.sys.id + " in field " + field.id, field.control, fieldmatch.control)
                  field_res.differences.push({
                    key: "control",
                    source_value: field.control,
                    target_value: fieldmatch.control,

                  });
                }
                if (field.defaultValue && fieldmatch.defaultValue) {
                  const intersection = Object.keys(field.defaultValue).filter(value => Object.keys(fieldmatch.defaultValue).includes(value));
                  for (const key in field.defaultValue) {
                    if (!intersection.includes(key)) delete field.defaultValue[key]
                  }
                  for (const key in fieldmatch.defaultValue) {
                    if (!intersection.includes(key)) delete fieldmatch.defaultValue[key]
                  }
                }
                var defaultValue_diff = this.deepDiffOperation(field.defaultValue, fieldmatch.defaultValue);
                if (defaultValue_diff) {
                  console.log("Default Value diff for model " + model.sys.id + " in field " + field.id, field.defaultValue, fieldmatch.defaultValue)
                  field_res.differences.push({
                    key: "defaultValue",
                    source_value: field.defaultValue,
                    target_value: fieldmatch.defaultValue,

                  });
                }

                //if there is any changes
                if (validation_diff != undefined || items_diff != undefined || control_diff != undefined || defaultValue_diff != undefined) {
                  field_res.perfect_field_match = false;
                }
              }
              cm_result.field_results.push(field_res); //push cm_result into result used for vuetify

              if (!field_res.perfect_field_match) {
                cm_result.perfect_match = false;
              }
            });
          } else {
            console.log("No match for content type " + model.sys.id);
          }
          this.result.push(cm_result); //push cm_result into result used for vuetify
        });
        this.finished_diff = true;
        this.show_results = true;
      } catch (err) {
        console.error(err);
        this.messages.push({ message: err.message, color: "error" });
      }
    },
    async getSpaces(refresh = false) {
      let cfSpaces = this.$store.state.contentfulSpaces;

      if (cfSpaces?.length > 0 && !refresh) {
        this.spaces = cfSpaces;
      } else {
        this.loading.spaces = true;
        this.$emit("loadingStatusChanged", true)
        this.axios
          .get(`/contentful/spaces`)
          .then((res) => {
            this.spaces = res.data.spaces ?? [];
            this.$store.commit("setContentfulSpaces", res.data.spaces);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          })
          .catch((err) => {
            this.messages.push({
              message: `Failed loading spaces! ${err.response ? err.response.data.message : err.message}`,
              color: "error",
            });
            console.error(err);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          });
      }
    },
  },

  mounted() {
    this.getSpaces();
  },
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}

.content_field_box {
  background-color: #7ecead61;
}

.inset {
  margin-left: 40px;
}
</style>
