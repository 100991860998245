<template>
  <v-container fluid>
    <snackbar-queue v-model="messages"></snackbar-queue>
    <div class="text-center">
      <h1>System for Cross-domain Identity Management (SCIM)</h1>
      <p>Manage Braze dashboard user accounts</p>
    </div>

    <div>
      <v-data-table :headers="headers" :items="users" class="elevation-1">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, key) in items" :key="key">
              <td class="">{{ item.name.givenName }}</td>
              <td class="">{{ item.name.familyName }}</td>
              <td class="">{{ item.userName }}</td>
              <td class="">{{ item.department }}</td>
              <td class="">{{ item.permissions }}</td>
              <td class="">{{ item.app_groups }}</td>
              <td class="">
                <v-icon small class="mr-1" @click="editUser(item)">mdi-account-edit</v-icon>
                <v-icon small class="mr-1" @click="copyUser(item)">mdi-content-copy</v-icon>
                <v-icon small @click="removeUser(key)">mdi-delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" slot="activator" color="primary" dark class="mb-2" @click="addUser">New User</v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.name.givenName" label="First Name"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.name.familyName" label="Last Name"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.userName" label="Email"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select v-model="editedItem.department" label="Department" :items="department_opts"></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select v-model="editedItem.permissions" label="Permissions" :items="permission_opts"
                    multiple></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <strong>App Groups</strong>
                  <ul>
                    <li v-for="(item, index) in editedItem.app_groups" :key="index">
                      {{ item }}
                      <button @click="removeAppGroup(index)"><v-icon small>mdi-delete</v-icon></button>
                    </li>
                  </ul>
                  <div>
                    <input v-model="newAppGroup" @keyup.enter="addAppGroup" placeholder="app group name">
                    <button @click="addAppGroup"><strong>ADD</strong></button>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn @click="saveUsers()">
        <v-icon left>mdi-content-save</v-icon>Save Users to Local Storage
      </v-btn>
      <v-btn @click="loadUsers()">
        <v-icon left>mdi-folder-download</v-icon>Load Users from Local Storage
      </v-btn>
      <br />
      <v-btn @click="sendUsers()" :loading="user_sending" color="green">Create User Accounts</v-btn>
      <v-alert type="info" dismissible v-model="json_panel.value">
        <vue-json-viewer :value="json_panel.data" :expand-depth=5 boxed></vue-json-viewer>
      </v-alert>
    </div>

  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue.vue";
import JsonViewer from "vue-json-viewer";
import lodash from "lodash";

export default {
  components: {
    "snackbar-queue": SnackBarQueue,
    "vue-json-viewer": JsonViewer,
  },
  data() {
    return {
      messages: [],

      users: [],
      json_panel: {
        data: {},
        value: false,
      },
      user_sending: false,
      headers: [
        { text: "First Name", align: "left", value: "name.givenName" },
        { text: "Last Name", value: "name.familyName" },
        { text: "E-Mail", value: "userName" },
        { text: "Department", value: "department" },
        { text: "Permissions", value: "permissions" },
        { text: "App Groups", value: "app_groups" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: {
          givenName: "",
          familyName: "",
        },
        userName: "",
        department: "",
        permissions: [],
        app_groups: [],
      },
      defaultItem: {
        name: {
          givenName: "",
          familyName: "",
        },
        userName: "",
        department: "marketing",
        permissions: [
          'basic_access',
          'export_user_data',
          'send_campaigns_canvases',
          'edit_segments',
          'view_usage_data',
          'manage_tags',
          'view_user_profile',
          'manage_events_attributes_purchases',
          'manage_email_settings',
          'manage_media_library',
          'import_update_user_data',
          'launch_content_blocks',
        ],
        app_groups: [],
      },
      dialog: false,
      newAppGroup: "",
      department_opts: [
        "agency",
        "bi",
        "c_suite",
        "engineering",
        "finance",
        "marketing",
        "pm",
      ],
      permission_opts: [
        'admin',
        'basic_access',
        'send_campaigns_canvases',
        'publish_cards',
        'edit_segments',
        'export_user_data',
        'view_pii',
        'view_user_profile',
        'manage_dashboard_users',
        'manage_media_library',
        'view_usage_data',
        'import_update_user_data',
        'view_billing_details',
        'dev_console',
        'manage_external_integrations',
        'manage_apps',
        'manage_teams',
        'manage_events_attributes_purchases',
        'manage_tags',
        'manage_email_settings',
        'manage_subscription_groups',
        'manage_approval_settings',
      ]
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },

  },
  methods: {
    display(message) {
      this.messages.push(message);
    },

    removeUser(key) {
      this.$delete(this.users, key);
    },
    addUser() {
      this.editedItem = lodash.cloneDeep(this.defaultItem)
    },
    copyUser(copy) {
      var new_user = {};
      if (copy) {
        new_user = lodash.cloneDeep(copy);
      } else {
        new_user = lodash.cloneDeep(this.defaultItem);
      }
      this.users.push(new_user);
    },
    editUser(user) {
      this.editedIndex = this.users.indexOf(user);
      this.editedItem = lodash.cloneDeep(user);
      this.dialog = true;
    },
    sendUsers() {
      console.log(this.users);
      this.json_panel.data = {};
      this.json_panel.value = false;

      const payload = this.users.map(user => {
        const appGroupPermissions = user.app_groups.map(app_group => {
          return {
            appGroupName: app_group,
            appGroupPermissions: user.permissions
          }
        })
        return {
          ...user,
          permissions: {
            appGroup: appGroupPermissions
          },
          app_groups: undefined,
          schemas: ["urn:ietf:params:scim:schemas:core:2.0:User"],
        }
      })

      this.user_sending = true;
      this.axios
        .post(`/customers/scim/create-users`, {
          users: payload,
        })
        .then((res) => {
          this.user_sending = false;
          this.messages.push({ message: "Request sent! Please review response.", color: "success", timeout: 3000 });
          if (res.data) {
            this.json_panel.data = res.data;
            this.json_panel.value = true;
          }
        })
        .catch((error) => {
          this.user_sending = false;
          this.json_panel.value = true;
          this.json_panel.data = error.response
            ? error.response.data
            : error.message;
        });
    },
    loadUsers() {
      let storageUsers = JSON.parse(window.localStorage.getItem("users_import"))
      if (storageUsers) {
        this.users.push(...storageUsers);
        this.messages.push({ message: "Loaded users!", timeout: 3000 });
      }
    },
    saveUsers() {
      window.localStorage.setItem("users_import", JSON.stringify(this.users));
      this.messages.push({ message: "Saved users!", timeout: 3000 });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.close();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = lodash.cloneDeep(this.defaultItem)
        this.editedIndex = -1;
        this.newAppGroup = "";
      }, 300);
    },
    removeAppGroup: function (index) {
      this.editedItem.app_groups.splice(index, 1);
    },
    addAppGroup: function () {
      if (this.newAppGroup.trim() !== '') {
        this.editedItem.app_groups.push(this.newAppGroup);
        this.newAppGroup = '';
      }

    },
  },
  mounted() {
  },
  created() { },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  background-color: rgba(80, 80, 80, 0.522);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}

.v-card {
  margin: 1em;
}

.textCa {
  display: flex;
  flex-direction: row;
}

.keyCa {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.propertyCard {
  text-align: left;
}
</style>
