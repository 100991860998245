<template>
  <v-container fluid v-if="customer">
    <snackbar-queue v-model="messages"></snackbar-queue>
    <div class="text-center">
      <h1>User Mass Edit Jobs for {{ customer.name }}</h1>
      <p>editing/changing/deleting users of entire Braze segments</p>
    </div>
    <div class="ma-5">
      <h2>
        Job History
        <v-btn icon @click="loadJobs()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </h2>
      <div v-if="jobs">
        <v-btn @click="jobs_expanded = !jobs_expanded" class="my-5">
          {{ jobs_expanded ? "show less jobs" : "show more jobs" }}
        </v-btn>
        <v-expansion-panels multiple focusable inset>
          <v-expansion-panel v-for="(job, index) in jobs_limited" :key="index">
            <v-expansion-panel-header>
              <span><b>Name:</b> {{ job.job_name }}</span>
              <span style="float: right"><b>Status:</b> {{ job.status }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Segment Name: {{ job.name }}
              <br />
              Start Time: {{ job.start_time }}
              <br />
              End Time: {{ job.end_time }} <br />
              Users:
              <v-progress-circular size="100" :value="job_progress(job)" :color="job_color(job)">
                {{ job.updated }}/{{ job.expected_users }}
              </v-progress-circular>
              <br />
              ZIP URL (only valid for 4 hours): <a :href="job.url">Download</a>
              <br />
              Messages:
              <ul class="job_message" v-for="(message, index) in job.messages" :key="index">
                <li>{{ message }}</li>
              </ul>
              Errors:
              <ul class="job_message" v-for="(error, index) in job.errors" :key="index">
                <li>{{ error }}</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </div>
    <v-divider class="primary" />
    <div class="ma-5">
      <h2>Create new Job</h2>
      <div v-if="customer && !customer.segments">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div v-if="customer && customer.segments">
        <v-row class="ma-1">
          <h3>
            Select Segment
          </h3>
          <v-btn icon @click="loadSegments()">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-row>
        <v-select v-model="selected_segment" :items="customer.segments" item-value="id" item-text="name"></v-select>

        <div>
          <div v-if="user_preview_loading">
            <v-progress-linear indeterminate></v-progress-linear>
          </div>
          <div class="user_preview" v-else-if="user_preview && !user_preview_loading">
            <h3>
              Segment Details
              <v-btn icon @click="loadUserpreview()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </h3>
            <div class="segment_stats bottom_separated">
              Total Segment Users: {{ user_preview }}
              <!-- <br />
              Total App Users: {{ user_preview.summary_statistics.total_app_users }}
              <br />
              Percentage in Segment:
              {{
                Math.round(
                  ((user_preview.summary_statistics.total_segment_users * 100) /
                    user_preview.summary_statistics.total_app_users) *
                    100
                ) / 100
              }}% -->
            </div>
            <v-data-table v-if="user_preview.user_directory" :headers="user_preview_headers"
              :items="user_preview.user_directory">
              <template slot="items" slot-scope="user">
                <td>{{ user.item.name }}</td>
                <td class="text-xs-right">{{ user.item.language }}</td>
                <td class="text-xs-right">{{ user.item.country }}</td>
                <td class="text-xs-right">{{ user.item.age }}</td>
                <td class="text-xs-right">{{ user.item.gender }}</td>
              </template>
            </v-data-table>
          </div>
        </div>
        <v-checkbox style="background-color: lightcoral;" v-model="delete_users" label="Delete Users (!)"></v-checkbox>
        <div v-if="!delete_users" style="background-color: aliceblue;">
          <h3 class="mt-5" >JSON to send</h3>
          Templates:
          <v-select v-model="selected_template_key" :items="saved_templates" item-value="id" item-text="displayname"
            dense></v-select>
          <br />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="formatJSON()" color="blue" v-on="on" v-bind="attrs">mdi-format-paint</v-icon>
            </template>
            <span>Format JSON</span>
          </v-tooltip>
          <v-textarea v-model="action_json" placeholder="The JSON to set on all users..." :auto-grow="true"
            :error-messages="json_errors" outline label="JSON"></v-textarea>
        </div>
        <v-text-field class="shrink" label="Job Name" v-model="job_name"
          placeholder="Give your job a name..."></v-text-field>
        <v-btn color="green" @click="execute_job()">Execute Job</v-btn>
        <div>
          <br>
          <h2>How it works</h2>
          <ol>
            <li>The server requests an export of the user data for the selected segment.</li>
            <li>
              Braze notifies the server when the export is ready. The server downloads the data and parses it.
            </li>
            <li>
              All users in the segment are updated with the specified mutation from the job.
            </li>
          </ol>
          Note: the maximal number of users the system can process is 1 million.
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import templates from "../../assets/user_mass_edit_templates";
//import JsonViewer from "vue-json-viewer";
import SnackBarQueue from "../helpers/SnackBarQueue";

export default {
  props: ["mandator"],
  components: {
    //"vue-json-viewer": JsonViewer,
    "snackbar-queue": SnackBarQueue,
  },
  data() {
    return {
      messages: [],
      job_name: "",
      selected_segment: "",
      user_preview: null,
      user_preview_loading: false,
      user_expandend: false,
      selected_template_key: "",
      action_json: "",
      saved_templates: templates,
      json_errors: [],
      jobs: null,
      jobs_expanded: false,
      delete_users: false,
      user_preview_headers: [
        {
          text: "Name",
          value: "name",
          align: "left",
        },
        {
          text: "Language",
          value: "language",
          align: "right",
        },
        {
          text: "Country",
          value: "country",
          align: "right",
        },
        {
          text: "Age",
          value: "age",
          align: "right",
        },
        {
          text: "Gender",
          value: "gender",
          align: "right",
        },
      ],
    };
  },
  computed: {
    customer() {
      return this.$store.getters.customer(this.mandator);
    },
    jobs_limited() {
      if (this.jobs) {
        if (this.jobs_expanded) {
          return this.jobs;
        } else {
          return this.jobs.slice(0, 5);
        }
      }
      return null;
    },
  },
  watch: {
    // customer(new_customer) {
    //   if (new_customer && !new_customer.segments) {
    //     this.loadSegments();
    //   }
    //   this.loadJobs();
    //   Object.assign(this.$data, this.$options.data());
    // },
    selected_segment: function (new_segment) {
      this.loadUserpreview();
    },
    selected_template_key(new_key) {
      this.action_json = JSON.stringify(templates.find((template) => template.id == new_key).template, null, 2);
    },
    // action_json(new_json) {
    //   this.validateJSON();
    // },
  },

  methods: {
    loadJobs() {
      this.jobs = null;
      this.axios
        .get(`/customers/mass-edit-jobs`, {
          params: {
            mandator: this.customer.mandator,
          },
        })
        .then((response) => {
          this.jobs = response.data;
          this.jobs.forEach((job) => {
            this.$set(job, "detailed", false);
          });
        })
        .catch((err) => {
          console.error(err);
          this.messages.push({
            message: `Failed loading jobs! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
        });
    },
    job_progress(job) {
      return (job.updated * 100) / job.expected_users;
    },
    job_color(job) {
      if (job.updated == job.expected_users) return "success";
      else if (job.updated == 0) return "error";
      else return "info";
    },
    loadSegments() {
      try {
        this.$store.dispatch("loadSegmentsForCustomer", this.customer.mandator);
      } catch (err) {
        console.error(err);
        this.messages.push({
          message: `Failed loading segments! ${err.response ? err.response.data.message : err.message}`,
          color: "error",
        });
      }
    },
    loadUserpreview() {
      if (this.customer && this.customer.segments && this.selected_segment != "") {
        this.user_preview = null;
        this.user_preview_loading = true;
        this.axios
          .get(`/customers/segment-user-preview`, {
            params: {
              mandator: this.customer.mandator,
              segmentId: this.selected_segment,
            },
          })
          .then((result) => {
            this.user_preview = result.data.size;
            this.user_preview_loading = false;
          })
          .catch((err) => {
            console.error(err);
            this.messages.push({
              message: `Failed loading Segment Preview! ${err.response ? err.response.data.message : err.message}`,
              color: "error",
            });
            this.user_preview_loading = false;
          });
      }
    },
    formatJSON() {
      if (this.action_json.length === 0) {
        return;
      }
      this.validateJSON();
      if (!this.json_errors?.length > 0) {
        this.action_json = JSON.stringify(JSON.parse(this.action_json), null, 2);
      } else {
        this.messages.push({
          message: `Failed formatting JSON!`,
          color: "error",
        });
      }
    },
    validateJSON() {
      try {
        this.$set(this, "json_errors", []);
        var action = JSON.parse(this.action_json);
        if (action.hasOwnProperty("external_id")) {
          this.json_errors.push("Property external_id not allowed...");
        }
        if (action.hasOwnProperty("user_alias")) {
          this.json_errors.push("Property user_alias not allowed...");
        }
        if (action.hasOwnProperty("braze_id")) {
          this.json_errors.push("Property braze_id not allowed...");
        }
        for (const key in action) {
          if (action.hasOwnProperty(key)) {
            const element = action[key];
            if (element == null) continue;
            if (Array.isArray(element)) {
              element.forEach(e => {
                if (typeof e === "object")
                  this.json_errors.push("An array may only include strings and numbers");
              })
            }
            else if (typeof element === "object") {
              if (!(element.hasOwnProperty("inc") || element.hasOwnProperty("remove") || element.hasOwnProperty("add"))) {
                this.json_errors.push("Only properties named inc, remove or add are allowed...");
              }
              if (element.hasOwnProperty("inc")) {
                if (!(typeof element.inc === "number")) {
                  this.json_errors.push("Every inc property needs an integer...");
                }
              }
              if (element.hasOwnProperty("remove")) {
                if (!Array.isArray(element.remove)) {
                  this.json_errors.push("The property remove needs an array...");
                }
              }
              if (element.hasOwnProperty("add")) {
                if (!Array.isArray(element.add)) {
                  this.json_errors.push("The property add needs an array...");
                }
              }
            }
          }
        }
      } catch (err) {
        console.error(err);
        this.json_errors.push(`${err.response ? err.response.data.message : err.message}`);
      }
    },
    execute_job() {
      this.validateJSON();
      if (
        this.customer &&
        this.customer.segments &&
        this.selected_segment != "" &&
        (!this.json_errors || this.json_errors.length == 0 || this.delete_users) &&
        this.user_preview != null &&
        this.job_name?.length > 0
      ) {
        try {
          var segment = this.customer.segments.find((segment) => {
            return segment.id == this.selected_segment;
          });
          this.axios
            .post(`/customers/start-mass-edit-job`, {
              mandator: this.customer.mandator,
              actionData: {
                segmentId: segment.id,
                segmentId_api: segment.api_identifier,
                name: segment.name,
                job_name: this.job_name,
                mutation: this.action_json,
                delete_users: this.delete_users,
              },
              expected_users: this.user_preview,
            })
            .then((result) => {
              this.messages.push(`${result.data.message}`);
            })
            .catch((err) => {
              console.error(err);
              this.messages.push({
                message: `Failed executing job! ${err.response ? err.response.data.message : err.message}`,
                color: "error",
              });
            });
        } catch (err) {
          console.error(err);
          this.messages.push({
            message: `Failed executing job! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
        }
      } else {
        this.messages.push({
          message: `Couldn't start Job - there are still JSON errors or no segment is selected!`,
          color: "error",
        });
      }
    },
  },
  created() {
    if (this.customer && !this.customer.segments) {
      this.loadSegments();
    }
    this.loadJobs();
  },
};
</script>
<style>
.bottom_separated {
  border-bottom: 1px solid #42b983;
}

textarea {
  resize: none;
  width: 50%;
  height: 20em;
  font-size: 1em;
}

.error {
  color: darkred;
}

.toggler {
  cursor: pointer;
}

.job {
  width: 50%;
}

.job_header {
  display: flex;
  justify-content: space-between;
}

.job_overview {
  display: flex;
}

.job_details {
  margin-left: 10px;
}

.job_message {
  margin: 10px;
}

.progress_bar_container {
  width: 300px;
  height: 1em;
  padding: 0;
  border: gray 1px solid;
}

.progress_bar {
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
