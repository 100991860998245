<template>
  <div class="text-center">
    <v-snackbar :timeout="-1" v-model="currentMessage.displayed" :color="currentMessage.color" :multi-line="true"
      transition="fab-transition">
      {{ currentMessage.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="currentMessage.displayed = false">
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      currentMessage: {
        displayed: false,
        message: "",
        color: "info",
        timeout: -1,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.tryShiftMessage();
      },
      deep: true,
    },
    currentMessage: {
      handler() {
        this.tryShiftMessage();
      },
      deep: true,
    },
  },
  methods: {
    tryShiftMessage() {
      if (!this.currentMessage.displayed && this.value && this.value.length > 0) {
        let message = this.value.shift();
        if (typeof message === "object") {
          if (!message.color) message.color = "info";
          this.currentMessage = message;
        } else if (typeof message === "string") {
          this.currentMessage = {
            message: message,
            color: "info",
          };
        }
        if (typeof this.currentMessage === "object") {
          this.$set(this.currentMessage, "displayed", true);
          if (this.currentMessage.timeout > 0)
            setTimeout(() => {
              this.$set(this.currentMessage, "displayed", false);
            }, this.currentMessage.timeout);
        }
      }
    },
  },
  mounted() { },
};
</script>
<style></style>
