import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

let activeMenuItem = window.localStorage.getItem("activeMenuItem");
//let loggedInUser = window.localStorage.getItem("loggedInUser");
let contentfulSpaces = window.localStorage.getItem("contentfulSpaces");

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || "0",
    activeMenuItem: activeMenuItem && activeMenuItem !== "" ? activeMenuItem : "dashboard",
    loggedInUser: {}, //loggedInUser && loggedInUser !== "undefined" ? JSON.parse(loggedInUser) : {},
    contentfulSpaces: contentfulSpaces && contentfulSpaces !== "undefined" ? JSON.parse(contentfulSpaces) : [],
    customers: [],
  },

  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    customers: (state) => {
      return state.customers;
    },
    customer: (state) => (mandator) => {
      if (!state.customers) {
        return undefined;
      }
      return state.customers.find((value) => {
        return value.mandator === mandator;
      });
    },
    loggedInUser: (state) => {
      return state.loggedInUser;
    },
    activeMenuItem: (state) => {
      return state.activeMenuItem;
    },
  },

  mutations: {
    setActiveMenuItem(state, item) {
      state.activeMenuItem = item;
      window.localStorage.setItem("activeMenuItem", state.activeMenuItem);
    },
    setLoggedInUser(state, item) {
      state.loggedInUser = item;
      window.localStorage.setItem("loggedInUser", JSON.stringify(state.loggedInUser));
    },
    setContentfulSpaces(state, item) {
      state.contentfulSpaces = item;
      window.localStorage.setItem("contentfulSpaces", JSON.stringify(state.contentfulSpaces));
    },
    setCustomers(state, customers) {
      if (customers)
        customers.forEach((customer) => {
          customer.expanded = false;
        });
      state.customers = customers;
    },
    setSegments(state, payload) {
      if (state.customers) {
        var customerIndex = state.customers.findIndex((customer) => {
          return customer.mandator == payload.mandator;
        });
        if (customerIndex != -1) Vue.set(state.customers[customerIndex], "segments", payload.segments);
      }
    },
    setSegmentError(state, payload) {
      if (state.customers) {
        var customerIndex = state.customers.findIndex((customer) => {
          return customer.mandator == payload.mandator;
        });
        if (customerIndex != -1) Vue.set(state.customers[customerIndex], "segments_error", payload.error);
      }
    },
  },

  actions: {
    loadCustomers({ commit }) {
      commit("setCustomers", null);
      var link = "/customers/customers";
      return axios
        .get(link, { headers: { authorization: 'Bearer ' + Vue.mainOidc.accessToken } })
        .then((response) => {
          let customers = response.data;
          commit("setCustomers", customers);
        })
        .catch((error) => {
          console.log("-----error-------");
          console.log(error);
        });
    },
    loadSegmentsForCustomer({ commit }, mandator) {
      commit("setSegments", { mandator, segments: null });
      return axios
        .get("/customers/segments", {
          params: {
            mandator: mandator,
          },
          headers: {
            authorization: 'Bearer ' + Vue.mainOidc.accessToken
          }
        })
        .then((response) => {
          commit("setSegments", { mandator, segments: response.data });
        })
        .catch((error) => {
          console.log("-----error-------");
          console.log(error);

          commit("setSegmentError", {
            mandator,
            error: error.response ? error.response.data.message : error.message,
          });
        });
    },
  },
});
