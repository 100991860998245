<template>
  <v-container fluid class="main">
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <!-- USER DIALOG -->
    <v-dialog v-if="dialog.user" v-model="dialog.user" transition="dialog-bottom-transition" max-width="600px">
      <v-card>
        <v-card-title class="text-center">
          {{ user.external_id }}
        </v-card-title>
        <v-card-subtitle class="mt-1">
          <span><v-icon>mdi-account</v-icon> {{ user.first_name }} {{ user.last_name }}</span>
          <v-spacer></v-spacer>
          <span><v-icon>mdi-email</v-icon> {{ user.email }}</span>
        </v-card-subtitle>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col>
                <v-card-title>
                  AWS Cards
                </v-card-title>
                <v-chip-group column>
                  <v-chip
                    v-for="card in sort(user.awsCards)"
                    :key="card.contentfulId"
                    @click="
                      dialog.awsCard = true;
                      awsCard = card;
                    "
                  >
                    {{ card.contentfulId }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col>
                <v-card-title>
                  JAMES MW Cards
                </v-card-title>
                <v-chip-group column>
                  <v-chip
                    v-for="card in sort(user.mwCards)"
                    :key="card.inputCard.contentfulId"
                    @click="
                      dialog.mwCard = card;
                      mwCard = card;
                    "
                  >
                    {{ card.inputCard.contentfulId }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog.user = false;
              user = {};
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- AWS CARD DIALOG -->
    <v-dialog v-if="dialog.awsCard" v-model="dialog.awsCard" transition="dialog-bottom-transition" max-width="533px">
      <v-card>
        <v-card-title>
          AWS Card Infos
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item> <strong>Contentful ID:</strong> {{ awsCard.contentfulId }} </v-list-item>
            <v-list-item>
              <strong>Sent At:</strong> {{ awsCard.timestamp ? new Date(awsCard.timestamp).toISOString() : "" }}
            </v-list-item>
            <v-list-item> <strong>Campaign ID:</strong> {{ awsCard.campaignId }} </v-list-item>
            <v-list-item> <strong>Language:</strong> {{ awsCard.language }} </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog.awsCard = false;
              awsCard = {};
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MW CARD DIALOG -->
    <v-dialog v-if="dialog.mwCard" v-model="dialog.mwCard" transition="dialog-bottom-transition" max-width="533px">
      <v-card class="mx-auto">
        <v-img class="white--text align-end" height="300px" :src="mwCard.teaserImageURL">
          <v-card-title>{{ mwCard.createdAt ? new Date(mwCard.createdAt).toISOString() : "" }}</v-card-title>
        </v-img>
        <v-card-title>
          {{ mwCard.title }}
        </v-card-title>
        <v-card-subtitle class="mt-1">
          {{ mwCard.subtitle }}
        </v-card-subtitle>
        <v-card-text class="text--primary">
          {{ mwCard.teaserText }}
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-title>JAMES MW Card Infos:</v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item> <strong>Contentful ID:</strong> {{ mwCard.inputCard.contentfulId || "" }} </v-list-item>
            <v-list-item> <strong>Content Type:</strong> {{ mwCard.cardLink.contentType || "" }} </v-list-item>
            <v-list-item> <strong>SEO Slug:</strong> {{ mwCard.cardLink.seoSlug || "" }} </v-list-item>
            <v-list-item>
              <strong>Created At:</strong> {{ mwCard.createdAt ? new Date(mwCard.createdAt).toISOString() : "" }}
            </v-list-item>
            <v-list-item> <strong>Campaign ID:</strong> {{ mwCard.inputCard.campaignAPIId || "" }} </v-list-item>
            <v-list-item> <strong>Language:</strong> {{ mwCard.language || "" }} </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog.mwCard = false;
              mwCard = {};
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="4">
        <h1>JAMES Journey Card Viewer</h1>
      </v-col>
      <v-col cols="4">
        <v-select
          outlined
          placeholder="Select a JAMES Customer"
          :items="customers"
          item-text="name"
          item-value="customerId"
          v-model="customerId"
          :loading="loading.customers"
        ></v-select>
      </v-col>
      <v-col cols="1">
        <v-btn icon @click="getCustomers()" :loading="loading.customers"> <v-icon>mdi-refresh</v-icon> </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn @click="refreshUsers()" :loading="loading.users">
          Refresh Userbase <br />
          from Braze <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3"> <v-text-field v-model="startDate" label="Start Date" append-icon="mdi-timer" /> </v-col>
      <v-col cols="3">
        <v-text-field v-model="endDate" label="End Date" append-icon="mdi-timer-off" />
      </v-col>
      <v-col cols="3">
        <v-btn
          :disabled="!valuesConfigured"
          @click="
            page = 1;
            getJourneys();
          "
          :loading="loading.journeys"
          color="#0097A7"
          class="white--text"
          >Get Journeys</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-alert color="indigo" dark>
        <v-icon style="float:right">mdi-information</v-icon>
        <ul>
          <li>Only JAMES configs with the configured field <strong>kubernetesNamespace</strong> can be selected.</li>
          <li>
            Make sure the connected Braze app group includes a segment called <strong>JAMES Journey Card Monitoring</strong>,
            which includes all valid JAMES users.
          </li>
          <li>
            JAMES production environments can only be inspected on JEMMA Admin production and JAMES staging environments only
            on JEMMA Admin staging.
          </li>
          <li>
            The tool can only view 10000 users at a time. Please use pagination to browse all users.
          </li>
        </ul>
      </v-alert>
    </v-row>
    <v-row style="display: block" class="rborder">
      <strong>Query Syntax:</strong>
      <ul>
        <li>A filter consists of three parts: a field, an operator, and a value.</li>
        <li>
          The following fields are supported:
          <ul>
            <li><code>external_id</code>: a users external_id in Braze.</li>
            <li><code>card_diff</code>: difference between stored cards in AWS and JAMES MW</li>
            <li><code>campaign_id</code>: Braze Campaign ID of the journey card</li>
            <li><code>mw_cards</code>: Amount of cards available in JAMES MW</li>
            <li><code>aws_cards</code>: Amount of cards stored in AWS</li>
          </ul>
        </li>
        <li>
          The following operators are supported:
          <ul>
            <li>external_id, campaign_id: <code>==</code>, <code>!=</code></li>
            <li>card_diff, mw_cards, aws_cards: <code>==</code>, <code>!=</code>, <code>>=</code>, <code>&#60;=</code></li>
          </ul>
        </li>
        <li>Multiple filters can be delimited by <code>;</code>.</li>
        <strong>Example:</strong>
        <code>external_id==user@mail.com;card_diff>=1;campaign_id!=brazeCampaignId;mw_cards>=3;aws_cards==4</code>
      </ul>
    </v-row>
    <v-row v-if="this.totalUsers > 0" class="text-center">
      <v-col>
        <v-alert color="indigo" dark>
          <v-icon>mdi-information</v-icon>
          Showing users {{ (this.page - 1) * 10000 }} to
          {{ Math.min((this.page - 1) * 10000 + this.users.length, this.totalUsers) }} of {{ this.totalUsers }} total users.
        </v-alert>
        <v-pagination
          :disabled="loading.journeys"
          @input="getJourneys()"
          v-model="page"
          :length="Math.ceil(this.totalUsers / 10000)"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        item-key="external_id"
        sort-by="external_id"
        :custom-filter="customFilter"
        @click:row="showUserCards"
        :items-per-page="25"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, 500, 1000] }"
      >
        <template v-slot:footer> </template>
        <template v-slot:top>
          <v-row>
            <v-text-field v-model="query" label="Query Bar" @keydown.enter="search = query" />
            <v-icon @click="search = query">mdi-magnify</v-icon>
          </v-row>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.diffAwsMwCards="{ item }">
          <v-chip :color="getColor(item.diffAwsMwCards)" dark>
            {{ item.diffAwsMwCards }}
          </v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-if="users.length > 0" v-slot:body.append>
          <tr style="background-color: #0097A7" class="white--text">
            <th class="title">{{ users.length }} Users</th>
            <th class="title">{{ sumField("#awsCards") }}</th>
            <th class="title">{{ sumField("#mwCards") }}</th>
            <th class="title">
              <v-chip :color="getColor(sumField('diffAwsMwCards'))" dark>
                {{ sumField("diffAwsMwCards") }}
              </v-chip>
            </th>
          </tr>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";
import moment from "moment-timezone";
export default {
  name: "Journeys",
  components: {
    SnackBarQueue,
  },

  data() {
    return {
      messages: [],
      dialog: {
        user: false,
        mwCard: false,
        awsCard: false,
      },
      loading: {
        users: false,
        customers: false,
        journeys: false,
      },
      pollJob: null,
      startDate: moment("2021-01-01")
        .tz("Europe/Vienna")
        .format()
        .split("+")[0],
      endDate: moment()
        .tz("Europe/Vienna")
        .format()
        .split("+")[0],
      totalUsers: 0,
      page: 1,
      customers: [],
      customerId: "",
      users: [],
      user: {},
      awsCard: {},
      mwCard: {},
      query: "",
      search: "",
      headers: [
        {
          text: "External ID",
          align: "start",
          filterable: true,
          value: "external_id",
        },
        {
          text: "Journey Cards in AWS",
          value: "#awsCards",
          filterable: false,
        },
        {
          text: "Journey Cards in JAMES MW",
          value: "#mwCards",
          filterable: false,
        },
        {
          text: "Difference AWS - MW ",
          value: "diffAwsMwCards",
          filterable: false,
        },
      ],
    };
  },
  computed: {
    valuesConfigured() {
      return this.customerId?.length > 0 && this.isIsoDate(this.startDate) && this.isIsoDate(this.endDate);
    },
  },
  methods: {
    sort(unordered) {
      return Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
        }, {});
    },
    showUserCards(user, options) {
      this.dialog.user = true;
      this.user = user;
    },
    isIsoDate(str) {
      if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) return true;
      return false;
    },
    customFilter(value, search, user) {
      const meetsCriteria = [];
      const filters = search.split(";");
      for (const filter of filters) {
        const query = filter.split(/(==|!=|<=|>=)/g); // should be three parts
        if (query.length !== 3 || query[2].length === 0) {
          meetsCriteria.push(true);
          continue;
        }
        let campaignIdExists = false; // for case campaign_id
        switch (query[0]) {
          case "external_id":
            switch (query[1]) {
              case "==":
                meetsCriteria.push(user.external_id.toString().indexOf(query[2]) !== -1);
                break;
              case "!=":
                meetsCriteria.push(!(user.external_id.toString().indexOf(query[2]) !== -1));
                break;
              default:
                meetsCriteria.push(true);
            }
            break;
          case "card_diff":
            switch (query[1]) {
              case "==":
                meetsCriteria.push(user.diffAwsMwCards === parseInt(query[2]));
                break;
              case "!=":
                meetsCriteria.push(user.diffAwsMwCards !== parseInt(query[2]));
                break;
              case ">=":
                meetsCriteria.push(user.diffAwsMwCards >= parseInt(query[2]));
                break;
              case "<=":
                meetsCriteria.push(user.diffAwsMwCards <= parseInt(query[2]));
                break;
              default:
                meetsCriteria.push(true);
            }
            break;
          case "mw_cards":
            switch (query[1]) {
              case "==":
                meetsCriteria.push(user["#mwCards"] === parseInt(query[2]));
                break;
              case "!=":
                meetsCriteria.push(user["#mwCards"] !== parseInt(query[2]));
                break;
              case ">=":
                meetsCriteria.push(user["#mwCards"] >= parseInt(query[2]));
                break;
              case "<=":
                meetsCriteria.push(user["#mwCards"] <= parseInt(query[2]));
                break;
              default:
                meetsCriteria.push(true);
            }
            break;
          case "aws_cards":
            switch (query[1]) {
              case "==":
                meetsCriteria.push(user["#awsCards"] === parseInt(query[2]));
                break;
              case "!=":
                meetsCriteria.push(user["#awsCards"] !== parseInt(query[2]));
                break;
              case ">=":
                meetsCriteria.push(user["#awsCards"] >= parseInt(query[2]));
                break;
              case "<=":
                meetsCriteria.push(user["#awsCards"] <= parseInt(query[2]));
                break;
              default:
                meetsCriteria.push(true);
            }
            break;
          case "campaign_id":
            switch (query[1]) {
              case "==":
                for (const cardId in user.mwCards) {
                  if (user.mwCards[cardId].inputCard.campaignAPIId === query[2]) {
                    campaignIdExists = true;
                  }
                }
                for (const cardId in user.awsCards) {
                  if (user.awsCards[cardId].campaignId === query[2]) {
                    campaignIdExists = true;
                  }
                }
                meetsCriteria.push(campaignIdExists);
                break;
              case "!=":
                meetsCriteria.push(user.diffAwsMwCards !== parseInt(query[2]));
                break;
              default:
                meetsCriteria.push(true);
            }
            break;
          default:
            meetsCriteria.push(true);
        }
      }
      for (const criteria of meetsCriteria) {
        if (!criteria) return false;
      }
      return true;
    },
    getCustomers() {
      this.loading.customers = true;
      this.axios
        .get("journeys/customers")
        .then((res) => {
          this.customers = res.data;
        })
        .catch((err) => {
          let message = err?.response?.data?.message ?? err.message;
          this.messages.push({ message, color: "error" });
        })
        .finally(() => {
          this.loading.customers = false;
        });
    },
    refreshUsers() {
      if (this?.customerId?.length === 0) {
        return this.messages.push("Please select a customer first!");
      }
      this.loading.users = true;
      this.axios
        .get("journeys/refresh-users", {
          params: {
            customerId: this.customerId,
          },
        })
        .then((res) => {
          this.pollJob = setInterval(() => {
            this.axios
              .get(`/journeys/get-job/`, {
                params: {
                  jobId: res.data.jobId,
                },
              })
              .then((res) => {
                if (res.data.progress == 100) {
                  // job finished
                  clearInterval(this.pollJob);
                  this.loading.users = false;
                  this.messages.push({ message: "Userbase updated!", color: "success" });
                }
              })
              .catch((err) => {
                clearInterval(this.pollJob);
                let message = err?.response?.data?.message ?? err.message;
                this.messages.push({ message, color: "error" });
                this.loading.users = false;
              });
          }, 5000);
        })
        .catch((err) => {
          this.loading.users = false;
          let message = err?.response?.data?.message ?? err.message;
          this.messages.push({ message, color: "error" });
        });
    },
    getJourneys() {
      this.loading.journeys = true;
      this.axios
        .get("journeys/all-journeys", {
          params: {
            customerId: this.customerId,
            timeRange: {
              from: this.startDate,
              to: this.endDate,
            },
            page: this.page,
          },
        })
        .then((res) => {
          this.users = res.data.users;
          this.totalUsers = res.data.totalUsers;
          this.messages.push({ message: "Loaded journeys!", color: "success" });
        })
        .catch((err) => {
          let message = err?.response?.data?.message ?? err.message;
          this.messages.push({ message, color: "error" });
        })
        .finally(() => {
          this.loading.journeys = false;
        });
    },
    getColor(diffCards) {
      if (diffCards !== 0) {
        return "red";
      } else {
        return "green";
      }
    },
    sumField(key) {
      // sum data in give key (property)
      return this.users.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
  created() {
    this.getCustomers();
  },
};
</script>

<style scoped>
.main {
  height: 98%;
  width: 98%;
  padding: 20px !important;
  margin: 20px !important;
}
.row {
  margin: 20px;
}
.rborder {
  border-radius: 25px;
  border: 2px solid #0097a7;
  padding: 10px;
}
</style>
