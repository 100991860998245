<template>
  <div>
    <v-text-field v-if="!countries" v-model="value" @change="(val) => this.$emit('input', val)"></v-text-field>
    <v-select
      v-if="countries"
      v-model="value"
      @change="(val) => this.$emit('input', val)"
      :items="countries"
      item-text="Name"
      item-value="Code"
      placeholder="Country"
    ></v-select>
  </div>
</template>
<script>
export default {
  props: ["selected"],
  watch: {
    selected(val) {
      this.value = val;
    },
  },
  data() {
    return {
      countries: null,
      value: this.selected,
    };
  },
  mounted() {
    this.countries = require("../data/all_countries.json");
  },
};
</script>
<style></style>
