export default [
  {
    id: "addIntTg",
    displayname: "Add Interest Tag",
    template: {
      "lks.tags": { add: ["lks.OffpisteSnowboards"] },
    },
  },
  {
    id: "changLang",
    displayname: "Change Language",
    template: {
      language: "de",
    },
  },
  {
    id: "changCntry",
    displayname: "Change Country",
    template: {
      country: "CH",
    },
  },
  {
    id: "changSubscr",
    displayname: "Change Subscription Status",
    template: {
      email_subscribe: "subscribed",
    },
  },
  {
    id: "remove",
    displayname: "Remove Custom Attribute",
    template: {
      my_custom_attr: null,
    },
  },
];
