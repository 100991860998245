<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Version Release</h2>
      <v-btn style="float:right" @click="getMasterSpace(); getModelVersions();">Refresh
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        Release a new content model version.
        The content model of the selected environment is stored in our 'james-content-model' Git repository
        and can later be used in 'Upgrade Version'.
        <br />
      </v-alert>
      <!-- select Spaces -->
      <strong>Source Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined disabled :items="[masterSpace]" item-text="name" item-value="id"
          v-model="masterSpace" />
        <v-select style="width:50%" outlined :items="masterSpaceEnvironments" item-text="name" return-object
          v-model="selection.masterEnvironment" />
      </v-row>
      <strong>Release Version</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>To change the version, you need to update the <strong>Model Version Information</strong> entry in the
          selected environment.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-text-field dense disabled outlined v-model="selection.releaseVersion" />
      </v-row>
      <v-row class="ma-2">
        <v-alert v-if="versionAlreadyReleased" color="yellow" dark>
          <v-icon>mdi-alert</v-icon>
          <span style="color: black">
            The version <strong>{{ selection.releaseVersion }}</strong> was already released by
            <strong>{{ versionReleasedBy }}</strong>. You can overwrite it by proceeding. Before doing so, please assert
            that this is necessary and does not break any existing deployments.</span>
        </v-alert>
      </v-row>
      <hr style="margin: 20px" />
      <strong>Exclude Fields</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Fields that will be ignored on every content type.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-autocomplete :disabled="contentModel.length == 0" outlined :items="contentModelFields" item-text="name"
          multiple return-object :clearable="true" v-model="excludedContentFields" />
      </v-row>
      <strong>Exclude a Field for a Specific Content Type</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Fields that will be ignored on the selected Content Type.</span>
      </v-tooltip>
      <template v-for="(contentTypeForFields, cIndex) of selectedContentTypeForFields">
        <v-row class="ma-2" v-if="!contentTypeForFields.deleted" :key="cIndex">
          <v-autocomplete :disabled="contentModel.length == 0" outlined :items="contentModel" item-text="name"
            return-object :clearable="true" v-model="contentTypeForFields.contentType" />
          <v-autocomplete :disabled="!contentTypeForFields.contentType" outlined
            :items="contentTypeForFields.contentType ? contentTypeForFields.contentType.fields : []" item-text="name"
            multiple :clearable="true" return-object v-model="contentTypeForFields.fields"
            :rules="[rules.isValid(contentTypeForFields)]" />
        </v-row>
      </template>
      <strong>Copy ONLY selected Content Types</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Only the selected Content Types will be copied.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-autocomplete :disabled="contentModel.length == 0" outlined :items="contentModel" item-text="name"
          :clearable="true" multiple return-object v-model="selectedContentTypes" />
      </v-row>
      <strong>Exclude selected Content Types</strong>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" v-on="on" v-bind="attrs"> mdi-information </v-icon>
        </template>
        <span>Content Types that will be ignored.</span>
      </v-tooltip>
      <v-row class="ma-2">
        <v-autocomplete :disabled="contentModel.length == 0" outlined :items="contentModel" item-text="name" multiple
          return-object :clearable="true" v-model="excludedContentTypes" />
      </v-row>
      <v-btn :color="versionAlreadyReleased ? 'yellow' : 'green'" @click="releaseModel()" :loading="loading.migration">{{
        versionAlreadyReleased ?
        "Re-Release Now" : "Release Now"
      }}</v-btn>
    </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";
import { sorting } from "./CopyModel.vue"

export default {
  data() {
    return {
      messages: [],
      masterSpace: null,
      masterSpaceEnvironments: [],
      spaces: [],
      loading: {
        spaces: false,
      },
      selection: {
        releaseVersion: null,
        masterEnvironment: null,
      },
      modelVersions: [],
      versionAlreadyReleased: false,
      versionReleasedBy: "",
      contentModel: [],
      contentModelFields: [],
      selectedContentTypes: null,
      excludedContentTypes: null,
      excludedContentFields: null,
      jobMessages: null,
      selectedContentTypeForFields: [
        {
          contentType: null,
          fields: [],
        },
      ],
      rules: {
        isValid: (v) => v.valid || "Item is required",
      },
    };
  },
  components: {
    SnackBarQueue,
  },
  watch: {
    "selection.masterEnvironment"() {
      if (this.selection.masterEnvironment) {
        this.selection.releaseVersion = this.selection.masterEnvironment.version
        this.getContentModel();
        const modelVersion = this.modelVersions.find(modelVersion => modelVersion.version === (this.selection.releaseVersion));
        if (modelVersion) {
          this.versionAlreadyReleased = true
          this.versionReleasedBy = modelVersion.releasedBy
        } else {
          this.versionAlreadyReleased = false
          this.versionReleasedBy = ""
        }
      }
      else {
        this.contentModel = [];
        this.contentModelFields = [];
        this.selectedContentTypes = null;
        this.excludedContentTypes = null;
        this.excludedContentFields = null;
        this.selectedContentTypeForFields = [
          {
            contentType: null,
            fields: [],
          },
        ];
      }
    },
    selectedContentTypeForFields: {
      deep: true,
      handler(val) {
        let notDeletd = val.filter((x) => !x.deleted);
        let empty = notDeletd.find((x) => x.contentType == null && x.fields.length == 0);
        let notEmpty = notDeletd.filter((x) => x.contentType != null && x.fields.length != 0);
        if (empty == null && notEmpty.length == notDeletd.length) {
          this.addToContentTypeForFields();
        }

        let count = 0;
        notDeletd.forEach((e) => {
          if (e.contentType == null && e.fields.length > 0) {
            e.fields = [];
          }
          e.valid = (e.contentType == null && e.fields.length == 0) || (e.contentType != null && e.fields.length > 0);

          if (notDeletd.length == 1) {
            e.deleted = false;
          } else if (e.contentType == null && e.fields.length == 0 && count != notDeletd.length - 1) {
            e.deleted = true;
          }
          count++;
        });
      },
    },
  },
  methods: {
    async getModelVersions() {
      this.loading.spaces = true;
      this.$emit("loadingStatusChanged", true)
      this.axios
        .get(`/contentful/content-model-versions`)
        .then(async (res) => {
          if (res && res.data) {
            this.modelVersions = res.data;
          }
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading master space! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
    },
    async getMasterSpaceVersion() {
      this.masterSpaceEnvironments = [];
      this.selection.releaseVersion = null;
      this.selection.masterEnvironment = null;
      this.versionAlreadyReleased = false;
      await this.axios
        .get(`/contentful/content-version-entries?spaceId=${this.masterSpace.id}`)
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            res.data.forEach((e) => {
              this.masterSpaceEnvironments.push({
                id: e.envId,
                name: `Version: ${e.version}, Environment: ${e.envId}`,
                version: e.version,
              });
            });
          }
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading master space entries! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        });
    },
    async getMasterSpace() {
      this.loading.spaces = true;
      this.$emit("loadingStatusChanged", true)
      this.axios
        .get(`/contentful/masterspace`)
        .then(async (res) => {
          if (res && res.data && res.data.space) {
            this.masterSpace = res.data.space;
            await this.getMasterSpaceVersion();
          }
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading master space! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => { this.loading.spaces = false; this.$emit("loadingStatusChanged", false) })
    },
    async getContentModel() {
      this.selectedContentTypes = null;
      this.excludedContentTypes = null;
      this.excludedContentFields = null;
      this.selectedContentTypeForFields = [
        {
          contentType: null,
          fields: [],
        },
      ];
      this.axios
        .get(`/contentful/contentModel?spaceId=${this.masterSpace.id}&environmentId=${this.selection.masterEnvironment.id}`)
        .then((res) => {
          if (res && res.data && res.data.contentModel) {
            this.contentModel = res.data.contentModel.items;
            this.contentModel.sort((a, b) => a.name.localeCompare(b.name));
            let fieldsArr = this.contentModel.map((x) => x.fields.map((f) => f.id));
            let ccArr = [];
            fieldsArr.forEach((e) => {
              ccArr = ccArr.concat(e);
            });
            this.contentModelFields = [...new Set(ccArr)].sort(sorting);
          } else {
            this.contentModel = [];
            this.contentModelFields = [];
          }
          this.loading.content = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error.message);
        });
    },
    addToContentTypeForFields() {
      this.selectedContentTypeForFields.push({
        contentType: null,
        fields: [],
      });
    },
    async releaseModel() {
      this.loading.spaces = true;
      const data = {
        ...this.selection,
        selectedContentTypes: this.selectedContentTypes,
        excludedContentTypes: this.excludedContentTypes,
        excludedContentFields: this.excludedContentFields,
        selectedContentTypeForFields: this.selectedContentTypeForFields.filter((x) => !x.deleted),
      }
      this.axios
        .post(`/contentful/release-version`, data)
        .then(async (res) => {
          this.messages.push({ message: "Successfully released model version!", color: "success" });
        })
        .catch((err) => {
          this.messages.push({
            message: `${err?.response?.data?.message ?? err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => this.loading.spaces = false);
    },
  },

  created() {
    this.getMasterSpace();
    this.getModelVersions();
  },
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}

.content_field_box {
  background-color: #7ecead61;
}

.inset {
  margin-left: 40px;
}
</style>
