<template>
  <v-container class="main" fluid>
    <v-alert :value="errorTitle !== ''" :v-if="errorTitle !== ''" type="error" dismissible flat class="notification" prominent
      v-model="errorAlertVisible"><span v-html="errorMessages"></span></v-alert>
    <v-alert :value="successTitle !== ''" :v-if="successTitle !== ''" type="success" dismissible flat class="notification"
      prominent v-model="successAlertVisible"><span v-html="successMessages"></span></v-alert>

    <!-- Navigation Menu -->
    <v-tabs v-model="tab" color="#00838F">
      <v-tab href="#resortSegmentBuilder">Ski Resort Segment Builder</v-tab>
    </v-tabs>

    <!-- Content -->
    <v-card flat>
      <div>
        <v-tabs-items v-model="tab">

          <!-- Overview -->
          <v-tab-item value="resortSegmentBuilder">
            <v-col>
              <v-card outlined flat>
                <v-row style="padding:10px;margin:10px;height:100%">
                  <v-col>
                    <h2>Add Segment</h2>
                    <v-divider class="horizontal-divider" /><br />
                    <v-text-field placeholder="Segment Name" outlined></v-text-field>
                    <v-text-field placeholder="Search Segmentss" outlined></v-text-field>

                    <v-row style="padding:0px;margin:0px;height:100%">
                      <v-btn class="primary-button" dark>Create Segment</v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <br />

              <v-card outlined flat>
                <v-col>
                  <h2>Ski Resorts</h2>
                  <v-divider class="horizontal-divider" /><br />
                  <v-row class="table-row-dense" v-for="(segment, index) of segments" :key="segment.id" no-gutters
                    align="center" justify="center">
                    <v-col style="padding:0px">
                      <span style="padding-left:5px;margin-left:5px;font-weight:bold"><v-checkbox :label="segment.name"
                          hide-details></v-checkbox></span><br />
                      <v-divider class="horizontal-divider-dense" v-if="index !== segments.length - 1"></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>


  </v-container>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'Skiline',

  components: {
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,

      errorTitle: '',
      errorDetail: '',
      successTitle: '',
      successDetail: '',

      errorAlertVisible: false,
      successAlertVisible: false,

      tab: null,
      segments: [
        { id: 1, name: "Resort 1" },
        { id: 2, name: "Resort 2" },
        { id: 3, name: "Resort 3" },
        { id: 4, name: "Resort 4" },
        { id: 5, name: "Resort 5" }
      ]
    }
  },

  computed: {
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
    },
  },

  methods: {


    closeAlert() {
      if (this.errorAlertVisible) {
        this.errorAlertVisible = false;
      } else if (this.successAlertVisible) {
        this.successAlertVisible = false;
      }
    }
  },

  mounted() {
    this.isLoading = false
  },

  watch: {
    errorAlertVisible() {
      if (this.errorAlertVisible) {
        setTimeout(this.closeAlert, 3000);
      }
    },
    successAlertVisible() {
      if (this.successAlertVisible) {
        setTimeout(this.closeAlert, 3000);
      }
    }
  },

};
</script>

<style scoped>
.main {
  height: 98%;
  width: 98%;
  padding: 20px !important;
  margin: 20px !important;
}

div {
  width: 100%;
  text-align: left;
}

.row-detail {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.v-tabs {
  padding: 0px !important;
  margin: 0px !important;
}

.v-tab {
  padding: 10px !important;
  margin: 10px !important;
}

.header-row {
  background-color: #efefef;
  font-weight: normal;
  font-size: 14pt;

  min-height: 50px;
  margin-left: 0px;
  margin-right: 30px;
  padding-right: 30px;
  margin-top: 0px;
  padding-top: 0px;
}

.table-row {
  margin-left: 10px;
  margin-right: 22px;
  padding-right: 22px;
  margin-top: 10px;
  padding-top: 10px;
}

.table-row-dense {
  margin-left: 10px;
  margin-right: 22px;
  padding-right: 22px;
  margin-top: 0px;
  padding-top: 0px;
}

.wrapper {
  min-height: 100vh;
  height: 100vh;
  background-color: #ffffff;
  padding: 10px;
  width: 90%
}</style>