<template>
  <v-container class="main" fluid fill-height>
    <v-row align="center" justify="center">
      <v-card id="card" flat>
        <v-card-text>
          <h1>Login to JEMMA</h1>
          <v-row>
            <v-col>
              <v-card flat>
                <v-btn class="primary-button" style="width:100%" dark @click="login">Login with Alturos</v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default {
  name: "Login",

  data: () => ({
    alertMessage: "",
    lastRoute: "",
  }),

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.lastRoute = from.fullPath;
    });
  },

  methods: {
    login() {
      Vue.mainOidc.signIn()
    },
  },
};
</script>

<style scoped>
.main {
  height: 100%;
  width: 100%;
  background-color: #2d2e2f;
}

#card {
  width: 600px;
  padding: 20px 40px 30px 40px;
}

h1 {
  color: black;
  font-size: 18pt;
  font-weight: 500;
  line-height: 50px;
}
</style>
