export default
 [
    "country",
    "current_location",
    "date_of_first_session",
    "date_of_last_session",
    "dob",
    "email",
    "email_subscribe",
    "external_id",
    "facebook",
    "first_name",
    "gender",
    "home_city",
    "image_url",
    "language",
    "last_name",
    "marked_email_as_spam_at",
    "phone",
    "push_subscribe",
    "push_tokens",
    "time_zone"
  ]