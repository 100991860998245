<template>
  <v-container fluid class="main">
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <!-- Navigation Menu -->
    <v-tabs v-model="tab" color="#00838F" show-arrows>
      <v-tab href="#customers" @click="showCustomers()">Customers</v-tab>
      <v-tab href="#user-info" @click="changeTab('user-info')" :disabled="!selectedCustomer">Info</v-tab>
      <v-tab href="#user-edit" @click="changeTab('user-edit')" :disabled="!selectedCustomer">User Edit</v-tab>
      <v-tab href="#user-mass-edit" @click="changeTab('user-mass-edit')" :disabled="!selectedCustomer">User Mass
        Edit</v-tab>
      <v-tab href="#user-import" @click="changeTab('user-import')" :disabled="!selectedCustomer">User Import</v-tab>
      <v-tab href="#user-csv-import" @click="changeTab('user-csv-import')" :disabled="!selectedCustomer">User CSV
        Importer</v-tab>
      <v-tab href="#story-statistics" @click="changeTab('story-statistics')" disabled>Story
        Statistics</v-tab>
      <v-tab href="#scim" disabled @click="changeTab('scim')">SCIM</v-tab>
    </v-tabs>

    <!-- Content -->
    <v-tabs-items v-model="tab">

      <!-- Customers -->
      <v-tab-item value="customers">
        <v-list nav dense rounded>
          <v-list-item v-for="(customer, index) in customers" :key="index" @click="selectCustomer(index)">
            <v-list-item-title>{{ customer.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-tab-item>

      <!-- Info -->
      <v-tab-item value="user-info" v-if="selectedCustomer">
        <div class="tab-item">
          <UserInfo :mandator="selectedCustomer.mandator" />
        </div>
      </v-tab-item>

      <!-- User Edit -->
      <v-tab-item value="user-edit" v-if="selectedCustomer">
        <div class="tab-item">
          <UserEdit :mandator="selectedCustomer.mandator" />
        </div>
      </v-tab-item>

      <!-- User Mass Edit -->
      <v-tab-item value="user-mass-edit" v-if="selectedCustomer">
        <div class="tab-item">
          <UserMassEdit :mandator="selectedCustomer.mandator" />
        </div>
      </v-tab-item>

      <!-- User Import -->
      <v-tab-item value="user-import" v-if="selectedCustomer">
        <div class="tab-item">
          <UserImport :mandator="selectedCustomer.mandator" />
        </div>
      </v-tab-item>

      <!-- User CSV Import -->
      <v-tab-item value="user-csv-import" v-if="selectedCustomer">
        <div class="tab-item">
          <UserCSVImporter :mandator="selectedCustomer.mandator" />
        </div>
      </v-tab-item>

      <!-- Story Statistics -->
      <v-tab-item value="story-statistics" v-if="selectedCustomer">
        <div class="tab-item">
          <StoryStatistics :mandator="selectedCustomer.mandator" />
        </div>
      </v-tab-item>

      <!-- SCIM -->
      <v-tab-item value="scim">
        <div class="tab-item">
          <SCIM />
        </div>
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import UserInfo from "./UserInfo";
import UserEdit from "./UserEdit";
import UserMassEdit from "./UserMassEdit";
import UserImport from "./UserImport";
import UserCSVImporter from "./UserCSVImporter";
import StoryStatistics from "./StoryStatistics";
import SnackBarQueue from "../helpers/SnackBarQueue";
import SCIM from "./SCIM.vue";

export default {
  name: "Customers",
  props: ["mandator", "activeTab"],
  components: {
    UserInfo,
    UserEdit,
    StoryStatistics,
    UserImport,
    UserCSVImporter,
    UserMassEdit,
    SnackBarQueue,
    SCIM
  },

  data() {
    return {
      messages: [],
      isLoading: false,
      tab: null,
      selectedCustomer: null,
    };
  },

  computed: {
    customer() {
      return this.$store.getters.customer(this.mandator);
    },
    customers() {
      return this.$store.getters.customers;
    },
  },

  methods: {
    showCustomers() {
      this.selectedCustomer = null;
      this.$router.replace("/braze");
    },

    selectCustomer(index) {
      this.selectedCustomer = this.customers[index];
      this.changeTab("user-info");
    },

    changeTab(tabName) {
      this.tab = tabName;
      if (this.tab === "scim")
        this.$router.replace("/braze/" + tabName);
      else this.$router.replace("/braze/" + this.selectedCustomer.mandator + "/" + tabName);
    },
  },

  async created() {
    const _this = this;
    this.$store.dispatch("loadCustomers").then(() => {
      _this.selectedCustomer = _this.customers.find((value) => {
        return value.mandator === _this.mandator;
      });
      _this.changeTab(_this.activeTab);
    });
  },
};
</script>

<style scoped>
.main {
  height: 98%;
  width: 98%;
  padding: 20px !important;
  margin: 20px !important;
}

.v-tabs {
  padding: 0px !important;
  margin: 0px !important;
}

.v-tab {
  padding: 10px !important;
  margin: 10px !important;
}

.tab-item {
  margin-top: 2rem;
}
</style>
