<template>
  <div>
    <v-text-field v-if="!languages" v-model="value" @change="(val) => this.$emit('input', val)"></v-text-field>
    <v-select
      v-if="languages"
      @change="(val) => this.$emit('input', val)"
      v-model="value"
      :items="languages"
      item-text="English"
      item-value="alpha2"
      placeholder="Language"
    ></v-select>
  </div>
</template>
<script>
export default {
  props: ["selected"],
  watch: {
    selected(val) {
      this.value = val;
    },
  },
  data() {
    return { languages: null, value: this.selected };
  },
  mounted() {
    this.languages = require("../data/all_langs.json");
  },
};
</script>
<style></style>
