<template>
  <v-container fluid v-if="customer">
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <h1>Story Variant Viewer for {{ customer.name }}</h1>
    <p>
      Performance overview and comparison tool for JAMES stories.
    </p>
    <div class="rborder">
      Select time range:
      <input class="d-inline-flex text-center" style="width:50px" type="number" v-model="time_range" />
      <v-select class="d-inline-flex" style="width: 100px" v-model="time_unit" :items="time_unit_items"></v-select>
      <v-btn @click="loadStories()" class="ml-2" color="green">Load Statistics</v-btn>
    </div>
    <div>
      <v-data-table
        class="elevation-1 text-left"
        :headers="headers"
        :items="stories"
        :loading="loading"
        item-key="id"
        id="statistic_table"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.fields.title }}</td>
              <td>{{ item.stats.deliver }}</td>
              <td>{{ item.stats.click }}</td>
              <td>{{ item.stats.read }}</td>
              <td>{{ item.stats.like }}</td>
              <td>{{ item.stats.click_deliver_percentage }}%</td>
              <td>{{ item.stats.read_click_percentage }}%</td>
              <td>{{ item.stats.like_read_percentage }}%</td>
              <td>
                <a :href="'https://app.contentful.com/spaces/' + customer.contentfulSpaceId + '/entries/' + item.id"
                  >Open in Contentful</a
                >
              </td>
            </tr>
          </tbody>
        </template>
        <template slot="expand" slot-scope="story">
          <v-card flat class="sub_info">
            <variants-view
              :story="story.item"
              :time_range="time_range"
              :expanded="true"
              :axios_defaults="axios_defaults"
              :inAdminUI="true"
              :space_id="customer.contentfulSpaceId"
            ></variants-view>
          </v-card>
        </template>
        <template slot="no-data">
          No data to display!
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import ms from "ms";
import querystring from "query-string";
import SnackBarQueue from "../helpers/SnackBarQueue.vue";
export default {
  props: ["mandator"],
  components: {
    SnackBarQueue,
  },
  data() {
    return {
      messages: [],
      stories: [],
      loading: false,
      colors: ["red", "blue", "green", "orange", "black"],
      axios_defaults: this.axios.defaults,
      headers: [
        {
          text: "Name",
        },
        {
          text: "Delivered",
        },
        {
          text: "Clicks",
        },
        {
          text: "Reads",
        },
        {
          text: "Likes",
        },
        {
          text: "Clicks/Delivered",
          tooltip: "Clicks to Delivered ratio",
        },
        {
          text: "Reads/Clicks",
          tooltip: "Reads to Click ratio",
        },
        {
          text: "Likes/Reads",
          tooltip: "Likes to Reads ratio",
        },
        {
          text: "Link",
          sortable: false,
        },
      ],
      time_range: "24",
      time_unit: "h",
      time_unit_items: [
        {
          value: "m",
          text: "minutes",
        },
        {
          value: "h",
          text: "hours",
        },
        {
          value: "d",
          text: "days",
        },
        {
          value: "y",
          text: "years",
        },
      ],
    };
  },
  computed: {
        customer(){
      return this.$store.getters.customer(this.mandator);
    },

  },
  watch: {},
  filters: {
    percentage: function(value) {
      if (!value) return "0%";
      if (typeof value === "string") value = parseFloat(value);
      var val_string = value.toFixed(2);
      value = /^(-*[0-9]*((\.0*[^0]+)|(?=\.0+)))/.exec(val_string)[1];
      return value + "%";
    },
  },
  methods: {
    changedGraphTags(story, newTags) {
      story.graph_tags_selected = newTags;
      story.graph_data.datasets.length = 0;
      newTags.forEach((tag) => {
        var dataPoints = [];
        var history = {};
        if (tag.path === "root") {
          history = story.stats_history;
        } else {
          history = story.variants.find((variant) => {
            return variant.id == tag.path;
          }).stats_history;
        }
        for (var date_epoch in history) {
          if (history.hasOwnProperty(date_epoch)) {
            var stats = history[date_epoch];
            if (typeof stats[tag.key] == "string") stats[tag.key] = parseFloat(stats[tag.key]);
            dataPoints.push({
              t: new Date(date_epoch * 1000),
              y: stats[tag.key],
            });
          }
        }
        var color = this.colors[story.graph_data.datasets.length % this.colors.length];
        story.graph_data.datasets.push({
          label: tag.text,
          data: dataPoints,
          borderColor: color,
          point: {
            backgroundColor: color,
            borderColor: color,
          },
        });
      });
    },
    expandStory(story) {
      story.expanded = !story.expanded;
      story.variants.forEach((variant) => {
        if (variant.isEditing === undefined) this.$set(variant, "isEditing", false); //isEditing Property
      });
      var timeframe = "t";
      if (this.time_range && this.time_range != "") {
        timeframe = this.time_range;
      }
      this.axios
        .get("/customers/" + this.mandator + "/variants/history", {
          params: {
            timeframe,
            historyFor: story.id,
          },
        })
        .then((response) => {
          const data = JSON.parse(response.data);
          var tags = [];
          function addToTags(tag) {
            if (
              !tags.find((t) => {
                return t.path === tag.path && t.key === tag.key;
              })
            ) {
              tags.push(tag);
            }
          }
          function addTagsFromStatsHistory(stats_history, parent, path = parent) {
            for (const stat_history_key in stats_history) {
              if (stats_history.hasOwnProperty(stat_history_key)) {
                var stat = stats_history[stat_history_key];
                for (const stat_key in stat) {
                  if (stat.hasOwnProperty(stat_key)) {
                    addToTags({
                      text: parent + "." + stat_key,
                      path,
                      key: stat_key,
                    });
                  }
                }
              }
            }
          }
          story.stats_history = data.stats_history;
          addTagsFromStatsHistory(story.stats_history, "Main", "root");
          data.variants.forEach((variant) => {
            var variant_story = story.variants.find((variant_story) => variant_story.id == variant.id);
            variant_story.stats_history = variant.stats_history;
          });
          story.variants.forEach((variant) => {
            addTagsFromStatsHistory(variant.stats_history, variant.fields.title, variant.id);
          });
          story.graph_tags = tags;
        })
        .catch((error) => {
          console.log("-----error-------");
          console.log(error);
          this.error = error.message;
        });
    },
    loadStories() {
      this.stories = [];
      this.error = null;
      let time = this.time_range + this.time_unit;
      if (!this.time_range?.length > 0) {
        return this.messages.push({ message: "Please select a time range!", color: "error" });
      }
      this.loading = true;
      this.axios
        .get("/customers/story-statistics", {
          params: {
            time_range: time,
            mandator: this.customer.mandator,
          },
        })
        .then((response) => {
          // deserialize response
          this.loading = false;
          this.messages.push({ message: "Successfully fetched stats!" });
          const data = response.data;
          data.forEach((story) => {
            story.expanded = false;
            story.graph_tag = "";
            story.graph_tags = [];
            story.graph_data = { datasets: [] };
            story.graph_tags_selected = [];
          });
          this.stories = data;
          console.log(this.stories[0]);
        })
        .catch((err) => {
          let message = err?.response?.data?.message ?? err.message;
          this.messages.push({ message, color: "error" });
          this.loading = false;
        });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  flex-direction: column;
}
.t_row {
  display: flex;
  flex-direction: row;
  margin: 5px;
}
.t_row_group {
  display: flex;
  flex-direction: column;
}
.t_item {
  overflow-x: hidden;
}
.col_name {
  flex: 0 0 20%;
}
.col_stat {
  flex: 0 0 8em;
}
.col_expander {
  flex: 0 0 40px;
  height: 30px;
  justify-self: center;
}
.graph {
  height: 400px;
  width: 80vw;
}
.sub_info {
  margin-left: 50px !important;
}
.v-row {
  color: red;
}
.rborder {
  border-radius: 25px;
  border: 2px solid #0097a7;
  padding: 10px;
}
</style>
