<template>
  <v-container fluid v-if="customer">
    <snackbar-queue v-model="messages"></snackbar-queue>
    <div class="text-center">
      <h1>User Editor for {{ customer.name }}</h1>
      <p>adding/editing information and attributes of single Braze users</p>
    </div>
    <div>
      <v-combobox v-model="userquery" label="External ID, E-Mail or Braze ID" @keyup.enter="loadUser" clearable
        :items="suggestedUsers" :loading="userFetchState.loading" append-outer-icon="mdi-account-search"
        @click:append-outer="loadUser"></v-combobox>
    </div>
    <div>
      <v-alert type="error" dismissible v-model="error_panel.value">
        <vue-json-viewer :value="error_panel.data" :expand-depth="5" boxed></vue-json-viewer>
      </v-alert>
    </div>
    <div v-if="showUserView">
      <v-row>
        <div class="overlay" v-if="userFetchState.loading">
          <v-progress-circular indeterminate style></v-progress-circular>
        </div>
        <v-card>
          <v-card-title>
            <h1>{{ user.first_name }} {{ user.last_name }}</h1>
          </v-card-title>
          <v-card-text>
            <strong>External ID: {{ user.external_id }}</strong>
            <br>
            <strong>Braze ID: {{ user.braze_id }}</strong>
          </v-card-text>
          <v-card-actions>
            <v-dialog v-model="deleteUserState.dialog">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :loading="deleteUserState.loading">
                  Delete
                  <v-icon color="red" right>mdi-delete</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                  Do you really want to delete the user?
                </v-card-title>

                <v-card-text>This action will be final and can't be undone </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="deleteUser">Yes</v-btn>
                  <v-btn color="primary" text @click="deleteUserState.dialog = false">No</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer />
            <v-btn @click="saveUser" :loading="saveUserState.loading">
              Save
              <v-icon color="green" right>mdi-content-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>

      <v-row>
        <v-card class="propertyCard">
          <v-card-title>
            <h2>User Attributes</h2>
          </v-card-title>
          <v-card-text>
            <table>
              <tr>
                <th>Property:</th>
                <th>Value:</th>
              </tr>
              <tr>
                <td>First Name:</td>
                <td>
                  <v-text-field v-model="user.first_name"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Last Name:</td>
                <td>
                  <v-text-field v-model="user.last_name"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>E-Mail:</td>
                <td>
                  <v-text-field v-model="user.email"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Subscription Status:</td>
                <td>
                  <v-select v-model="user.email_subscribe" :items="[
                    { label: 'Opted In', val: 'opted_in' },
                    { label: 'Subscribed', val: 'subscribed' },
                    { label: 'Unsubscribed', val: 'unsubscribed' },
                  ]" item-text="label" item-value="val"></v-select>
                </td>
              </tr>
              <tr>
                <td>Gender:</td>
                <td>
                  <v-select v-model="user.gender" :items="[
                    { label: 'Male', val: 'M' },
                    { label: 'Female', val: 'F' },
                    { label: 'Other', val: 'O' },
                    { label: 'Prefer not to say', val: 'P' },
                    { label: 'Not applicable', val: 'N' },
                    { label: 'Unkown', val: 'null' },
                  ]" item-text="label" item-value="val"></v-select>
                </td>
              </tr>
              <tr>
                <td>DOB:</td>
                <td>
                  <v-menu ref="dobMenu" v-model="dobMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="user.dob" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                        class="input-field" clearable @click:append="dobMenu = !dobMenu" @click:clear="user.dob = null">
                      </v-text-field>
                    </template>
                    <v-date-picker ref="dobPicker" v-model="user.dob" :max="new Date().toISOString().substr(0, 10)"
                      :first-day-of-week="1"></v-date-picker>
                  </v-menu>
                </td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>
                  <country-picker :selected="user.country" @input="(event) => (this.user.country = event)">
                  </country-picker>
                </td>
              </tr>
              <tr>
                <td>Home City:</td>
                <td>
                  <v-text-field v-model="user.home_city"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>Language:</td>
                <td>
                  <language-picker :selected="user.language" @input="(event) => (this.user.language = event)">
                  </language-picker>
                </td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
        <v-card class="propertyCard">
          <v-card-title>
            <h2>Custom Attributes</h2>
          </v-card-title>
          <v-card-text>
            <table>
              <tr>
                <th>Property:</th>
                <th>Value:</th>
              </tr>
              <tr v-for="(ca, index) in custom_attributes" :key="index">
                <td :class="{ keyCa: ca.type == `array` }">{{ ca.key }}</td>
                <td>
                  <template v-if="ca.type == `array`">
                    <div v-for="(arr_val, arr_index) in ca.value" :key="arr_index" class="textCa">
                      <v-text-field v-model="ca.value[arr_index]" dense></v-text-field>
                      <v-btn :icon="true" @click="deleteArrIdx(ca.key, arr_index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>

                    <v-btn :icon="true" @click="addArrIdx(ca.key)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-btn :icon="true" @click="deleteAttr(ca.key)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <div class="textCa">
                      <v-text-field v-model="ca.value" style="width:90%" dense :type="ca.type"></v-text-field>

                      <v-btn :icon="true" @click="deleteAttr(ca.key)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </td>
              </tr>
            </table>

            <div class="textCa">
              <v-text-field v-model="new_attr" label="New Attribute name" style="width:90%"></v-text-field>
              <v-btn :icon="true" @click="addAttr">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CountryPicker from "./helpers/CountryPicker.vue";
import LanguagePicker from "./helpers/LanguagePicker.vue";
import SnackBarQueue from "../helpers/SnackBarQueue.vue";
import JsonViewer from "vue-json-viewer";

export default {
  props: ["mandator"],
  components: {
    "country-picker": CountryPicker,
    "language-picker": LanguagePicker,
    "snackbar-queue": SnackBarQueue,
    "vue-json-viewer": JsonViewer,
  },
  data() {
    return {
      userquery: "",
      userFetchState: {
        loading: false,
        notfound: false,
        error: false,
        loaded: false,
        message: "",
      },
      saveUserState: {
        loading: false,
        error: false,
        loaded: false,
      },
      deleteUserState: {
        loading: false,
        error: false,
        loaded: false,
        dialog: false,
      },
      user: null,
      messages: [],
      userHistory: null,
      new_attr: "",
      error_panel: {
        data: {},
        value: false,
      },
      dobMenu: false,
    };
  },
  computed: {
    customer() {
      return this.$store.getters.customer(this.mandator);
    },
    showUserView() {
      return this.userFetchState.loaded && !this.userFetchState.loading;
    },
    suggestedUsers() {
      return this.userHistory ?? [];
    },
    custom_attributes() {
      let ca_list = [];
      for (const ca_key in this.user.custom_attributes) {
        if (this.user.custom_attributes.hasOwnProperty(ca_key)) {
          const ca_val = this.user.custom_attributes[ca_key];
          if (ca_val != null) {
            let ca = {
              key: ca_key,
              value: ca_val,
              type: "text",
            };

            if (Array.isArray(ca_val)) {
              ca.type = "array";
            } else if (typeof ca_val == "number") {
              ca.type = "number";
            }
            ca_list.push(ca);
          }
        }
      }
      return ca_list;
    },
  },
  watch: {
    userquery() {
      localStorage.userquery = this.userquery;
    },
  },
  methods: {
    addAttr(evt) {
      this.$set(this.user.custom_attributes, this.new_attr, "");
      this.new_attr = "";
    },
    deleteAttr(ca_key) {
      this.user.custom_attributes[ca_key] = null;
    },
    addArrIdx(ca_key) {
      this.user.custom_attributes[ca_key].push("");
    },
    deleteArrIdx(ca_key, idx) {
      this.$delete(this.user.custom_attributes[ca_key], idx);
    },
    async deleteUser() {
      this.deleteUserState.dialog = false;
      this.deleteUserState.loaded = false;
      this.deleteUserState.loading = true;

      if (this.user) {
        try {
          let response = await this.axios.delete(`/customers/user/delete`, {
            params: {
              mandator: this.customer.mandator,
            },
            data: this.user,
          });
          this.deleteUserState.loaded = true;
          this.display({ message: "Deleted user!", color: "success" });
        } catch (err) {
          this.deleteUserState.error = true;
          this.display({
            color: "error",
            message: `Failed deleting user! ${err.response ? err.response.data.message : err.message}`,
          });
        }
      } else {
        this.display("Please select a valid user");
      }
      this.deleteUserState.loading = false;
    },
    async loadUser() {
      this.userFetchState.loading = true;
      this.error_panel.value = false;

      if (this.userquery && this.userquery.length > 0) {
        try {
          let response = await this.axios.get(`/customers/user/export`, {
            params: {
              mandator: this.customer.mandator,
              query: this.userquery,
            },
          });

          if (response.data.users?.length > 0) {
            this.userHistory.push(this.userquery);
            localStorage.userqueryHist = JSON.stringify(this.userHistory);
            this.userFetchState.loaded = true;
            this.user = response.data.users[0];
            if (this.user.dob) this.user.dob = this.user.dob.slice(0, 10);
            this.display("Loaded user!");
          } else {
            this.userFetchState.loaded = false;
            this.display({
              color: "error",
              message: "Could not find any users!",
            });
          }
        } catch (err) {
          this.display({
            color: "error",
            message: `Failed loading users! ${err.response ? err.response.data.message : err.message}`,
          });
          this.userFetchState.error = true;
          this.userFetchState.message = "Error";
        }
      } else {
        this.display({ message: "Please enter something!", timeout: 5000 });
        this.userFetchState.loaded = false;
      }

      this.userFetchState.loading = false;
    },
    async saveUser() {
      this.saveUserState.loaded = false;
      this.saveUserState.loading = true;
      this.error_panel.value = false;

      if (this.user) {
        let user_tosend = JSON.parse(JSON.stringify(this.user));
        this.custom_attributes.forEach((ca) => {
          user_tosend.custom_attributes[ca.key] = ca.value;
        });
        try {
          console.log("User:")
          console.log(this.user);
          let response = await this.axios.post(`/customers/user/save`, {
            mandator: this.customer.mandator,
            user: user_tosend,
          });
          this.saveUserState.loaded = true;
          this.display({ message: "Saved user!", color: "success" });
        } catch (err) {
          this.display({ message: "Failed saving user!", color: "error" });
          this.saveUserState.error = true;
        }
      } else {
        this.display("Please select a valid user");
      }
      this.saveUserState.loading = false;
    },
    display(message) {
      this.messages.push(message);
    },
  },
  mounted() {
    this.userquery = this.$route.query.q || "";
    try {
      this.userHistory = localStorage.userqueryHist ? JSON.parse(localStorage.userqueryHist) : null;
    } catch (e) {
      console.error(e);
    }
    if (!Array.isArray(this.userHistory)) {
      this.userHistory = [];
    }
  },
  created() { },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  background-color: rgba(80, 80, 80, 0.522);
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}

.v-card {
  margin: 1em;
}

.textCa {
  display: flex;
  flex-direction: row;
}

.keyCa {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.propertyCard {
  text-align: left;
}
</style>
