<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>
    <v-card outlined flat class="pa-10">
      <h2 style="display: inline">Run Script</h2>
      <v-btn style="float:right" @click="getSpaces(true)">Refresh Contentful Spaces
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-divider class="horizontal-divider" /><br />
      <v-alert color="indigo" dark>
        <v-icon>mdi-information</v-icon>
        Run a script in a selected space and environment
        <br />
      </v-alert>
      <strong>Select a script</strong>
      <v-row class="ma-2">
        <v-select outlined clearable :items="scripts" item-text="name" item-value="id" v-model="selection.script" />
      </v-row>
      <strong>or upload a script</strong>
      <v-row class="ma-2">
        <v-file-input accept=".js,.ts" multiple label="File upload" v-model="files"></v-file-input>
      </v-row>
      <strong>Target Space</strong>
      <v-row class="ma-2">
        <v-select style="width:50%" outlined :items="spaces" item-text="name" item-value="id"
          v-model="selection.targetSpace" />
        <v-select style="width:50%" :disabled="selection.targetSpace == null" outlined :items="targetEnvs"
          item-text="name" value="id" v-model="selection.targetEnv" />
      </v-row>
      <!-- <strong>Script Type</strong>
      <v-row class="ma-2 mt-0">
        <v-radio-group v-model="selection.type">
          <v-radio :label="`Migration Script`" value="migration"></v-radio>
          <v-radio :label="`Regular Script`" value="regular"></v-radio>
        </v-radio-group>
      </v-row> -->
      <v-btn :disabled="!(selection.targetSpace && selection.targetEnv && (files.length > 0 || selection.script))"
        class="primary-button" dark @click="executeScript()" :loading="loading.migration">Execute</v-btn>
    </v-card>
    <br />
    <v-card class="mt-2" outlined flat v-if="migartionResponse && migartionResponse.length > 0">
      <v-row class="ma-2" v-for="(log, mIndex) of migartionResponse" :key="mIndex">
        <p>{{ log }}</p>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import SnackBarQueue from "../helpers/SnackBarQueue";

export default {
  data() {
    return {
      messages: [],
      loading: {
        spaces: false,
        migration: false,
      },
      selection: {
        targetSpace: null,
        targetEnv: null,
        script: null,
        // type: "migration"
      },
      files: [],
      spaces: [],
      scripts: [],
      result: null,
      uploadPercentage: null,
      migartionResponse: null,
      pollJob: null,
      jobProgress: 0,
      jobMessages: null,
    };
  },
  components: {
    SnackBarQueue,
  },
  watch: {
    "selection.targetSpace"() {
      this.selection.targetEnv = null;
    },
  },
  computed: {
    targetEnvs() {
      if (this.selection.targetSpace) {
        let space = this.spaces.find((space) => this.selection.targetSpace === space.id);
        if (space && space.environments) return space.environments;
      }
      return [];
    },
  },

  methods: {
    async executeScript() {
      this.loading.migration = true;
      let bodyFormData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        bodyFormData.append("formFile", file);
      }

      bodyFormData.append("space", this.selection.targetSpace);
      bodyFormData.append("environment", this.selection.targetEnv);
      if (this.selection.script) bodyFormData.append("script", this.selection.script);
      // bodyFormData.append("type", this.selection.type);
      await this.axios
        .post(`/contentful/upload`, bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          }.bind(this),
        })
        .then((res) => {
          this.pollJob = setInterval(() => {
            this.axios
              .get(`/contentful/get-job/`, {
                params: {
                  jobId: res.data.jobId,
                },
              })
              .then((res) => {
                // job finished AND data is returned AND this is the first response
                if (res.data.progress === 100 && (res.data.results || res.data.failed) && this.result == null) {
                  clearInterval(this.pollJob);
                  this.loading.migration = false;
                  this.jobProgress = 0;
                  this.jobMessages = res.data.logs;
                  if (res.data.failed) {
                    this.messages.push({
                      message: `Failed executing migration script! ${res.data.message}`,
                      color: "error",
                    });
                  } else {
                    this.migartionResponse = res.data.results;
                    this.messages.push({ message: "Successfully executed migration script!", color: "success" });
                  }
                } else {
                  this.jobProgress = res.data.progress;
                  this.jobMessages = res.data.logs;
                }
              })
              .catch((err) => {
                clearInterval(this.pollJob);
                this.loading.migration = false;
                this.jobProgress = 0;
                this.jobMessages = res.data.logs;
                this.messages.push({
                  message: `Failed executing migration script! ${err.response ? err.response.data.message : err.message}`,
                  color: "error",
                });
                console.error(err);
              });
          }, 1000);
        })
        .catch((err) => {
          this.loading.migration = false;
          clearInterval(this.pollJob);
          this.messages.push({
            message: `Failed executing migration script! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.uploadPercentage = 0;
          }, 2500);
        });
    },
    async getSpaces(refresh = false) {
      this.selection.targetSpace = null;
      let cfSpaces = this.$store.state.contentfulSpaces;

      if (cfSpaces?.length > 0 && !refresh) {
        this.spaces = cfSpaces;
      } else {
        this.loading.spaces = true;
        this.$emit("loadingStatusChanged", true)
        this.axios
          .get(`/contentful/spaces`)
          .then((res) => {
            this.spaces = res.data.spaces ?? [];
            this.$store.commit("setContentfulSpaces", res.data.spaces);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          })
          .catch((err) => {
            this.messages.push({
              message: `Failed loading spaces! ${err.response ? err.response.data.message : err.message}`,
              color: "error",
            });
            console.error(err);
            this.loading.spaces = false;
            this.$emit("loadingStatusChanged", false)
          });
      }
    },
    async getScripts() {
      this.selection.script = null;
      this.axios
        .get(`/contentful/scripts`)
        .then((res) => {
          this.scripts = res.data.scripts ?? [];
        })
        .catch((err) => {
          this.messages.push({
            message: `Failed loading scripts! ${err.response ? err.response.data.message : err.message}`,
            color: "error",
          });
          console.error(err);
        });

    },
  },

  mounted() {
    this.getSpaces();
    this.getScripts();
  },
};
</script>

<style lang="scss" scoped>
.customer {
  position: relative;
}

.content_field_box {
  background-color: #7ecead61;
}

.inset {
  margin-left: 40px;
}
</style>
