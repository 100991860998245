<template>
  <v-container fluid v-if="customer">
    <snackbar-queue v-model="messages"></snackbar-queue>
    <div class="text-center">
      <h1>User Importer for {{ customer.name }}</h1>
      <p>imports new users to the app group</p>
    </div>
    <p>Notes
    <ul>
      <li>Custom attributes must be in JSON format: <span style="color: blue">{"key1": "value1", "key2": "value2"}</span>
      </li>
    </ul>
    </p>
    <div>
      <v-data-table :headers="headers" :items="users" class="elevation-1">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, key) in items" :key="key">
              <td class="">{{ item.first_name }}</td>
              <td class="">{{ item.last_name }}</td>
              <td class="">{{ item.email }}</td>
              <td class="">{{ item.gender }}</td>
              <td class="">{{ item.dob }}</td>
              <td class="">{{ item.language }}</td>
              <td class="">{{ item.country }}</td>
              <td class="">{{ item.home_city }}</td>
              <td class="">{{ item.zip }}</td>
              <td class="">{{ item.email_subscribe }}</td>
              <td class="">{{ item.custom_attribute }}</td>
              <td class="">
                <v-icon small class="mr-1" @click="editUser(item)">mdi-account-edit</v-icon>
                <v-icon small class="mr-1" @click="addUser(item)">mdi-content-copy</v-icon>
                <v-icon small @click="removeUser(key)">mdi-delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" max-width="800px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" slot="activator" color="primary" dark class="mb-2">New User</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.first_name" label="First Name"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.last_name" label="Last Name"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select v-model="editedItem.gender" label="Gender" :items="genders"></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field placeholder="YYYY-MM-DD" v-model="editedItem.dob" label="DOB"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <country-picker :selected="editedItem.country"
                    @input="(event) => (editedItem.country = event)"></country-picker>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <language-picker :selected="editedItem.language"
                    @input="(event) => (editedItem.language = event)"></language-picker>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.home_city" label="Home City"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field v-model="editedItem.zip" label="ZIP"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-select v-model="editedItem.email_subscribe" label="Subscribe Status"
                    :items="subscribe_opts"></v-select>
                </v-flex>
                <v-flex>
                  <v-text-field v-model="editedItem.custom_attribute" label="Custom Attributes"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn @click="saveUsers()">
        <v-icon left>mdi-content-save</v-icon>Save User to local Storage
      </v-btn>
      <v-btn @click="loadUsers()">
        <v-icon left>mdi-folder-download</v-icon>Load Users from Local Storage
      </v-btn>
      <br />
      <v-btn @click="sendUsers()" :loading="user_sending" color="green">Send Users to Braze</v-btn>
      <v-alert type="error" dismissible v-model="error_panel.value">
        <vue-json-viewer :value="error_panel.data" :expand-depth=5 boxed></vue-json-viewer>
      </v-alert>
    </div>
    <br />
  </v-container>
</template>

<script>
import CountryPicker from "./helpers/CountryPicker.vue";
import LanguagePicker from "./helpers/LanguagePicker.vue";
import JsonViewer from "vue-json-viewer";
import SnackBarQueue from "../helpers/SnackBarQueue";
export default {
  props: ["mandator"],
  components: {
    "country-picker": CountryPicker,
    "language-picker": LanguagePicker,
    "vue-json-viewer": JsonViewer,
    "snackbar-queue": SnackBarQueue,
  },
  data() {
    return {
      messages: [],
      users: [],
      error_panel: {
        data: {},
        value: false,
      },
      user_sending: false,
      subscribe_opts: [
        {
          text: "Opted In",
          value: "opted_in",
        },
        {
          text: "Subscribed",
          value: "subscribed",
        },
        {
          text: "Unsubscribed",
          value: "unsubscribed",
        },
      ],
      genders: [
        {
          text: "Female",
          value: "F",
        },
        {
          text: "Male",
          value: "M",
        },
        {
          text: "Other",
          value: "O",
        },
        {
          text: "Not specified",
          value: "N",
        },
        {
          text: "Prefer not to say",
          value: "P",
        },
      ],
      headers: [
        { text: "First Name", align: "left", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "E-Mail", value: "email" },
        { text: "Gender", value: "gender" },
        { text: "DOB", value: "dob" },
        { text: "Language", value: "language" },
        { text: "Country", value: "country" },
        { text: "Home City", value: "home_city" },
        { text: "ZIP", value: "zip" },
        { text: "Email Subscribe", value: "email_subscribe" },
        { text: "Custom Attribute", value: "custom_attribute" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        first_name: "",
        last_name: "",
        gender: "",
        dob: "",
        country: "",
        language: "",
        email: "",
        home_city: "",
        zip: "",
        email_subscribe: "subscribed",
        custom_attribute: "",
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        gender: "",
        dob: "",
        country: "",
        language: "",
        email: "",
        home_city: "",
        zip: "",
        email_subscribe: "subscribed",
        custom_attribute: "",
      },
      dialog: false,
    };
  },
  computed: {
    customer() {
      return this.$store.getters.customer(this.mandator);
    },

    site_title() {
      return "User Import Tool for " + this.customer.name;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },
  watch: {
    customer(new_customer) { },
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    removeUser(key) {
      this.$delete(this.users, key);
    },
    addUser(copy) {
      var new_user = {};
      if (copy) {
        new_user = JSON.parse(JSON.stringify(copy));
      } else {
        new_user = {
          first_name: "",
          last_name: "",
          gender: "",
          dob: "",
          country: "",
          language: "",
          email: "",
          home_city: "",
          zip: "",
          email_subscribe: "",
          custom_attribute: "",
        };
      }
      this.users.push(new_user);
    },
    editUser(user) {
      this.editedIndex = this.users.indexOf(user);
      this.editedItem = Object.assign({}, user);
      this.dialog = true;
    },
    sendUsers() {
      console.log(this.users);
      this.error_panel.data = {};
      this.error_panel.value = false;
      this.user_sending = true;
      this.axios
        .post(`/customers/add-users`, {
          mandator: this.customer.mandator,
          users: this.users,
        })
        .then((res) => {
          this.user_sending = false;
          this.messages.push({ message: res.data.message, color: "success" });
          if (res.data.minor_errors) {
            this.error_panel.data = res.data.minor_errors;
            this.error_panel.value = true;
          }
        })
        .catch((error) => {
          this.user_sending = false;
          this.error_panel.value = true;
          this.error_panel.data = error.response
            ? error.response.data
            : error.message;
        });
    },
    loadUsers() {
      let storageUsers = JSON.parse(window.localStorage.getItem("users_import"))
      if (storageUsers) {
        this.users.push(...storageUsers);
        this.messages.push("Loaded users!");
      }
    },
    saveUsers() {
      window.localStorage.setItem("users_import", JSON.stringify(this.users));
      this.messages.push("Saved users!");
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.close();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin: 0.5rem;
}
</style>
